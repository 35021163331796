import { slice } from "lodash";
import InvoiceItemPrice from "./InvoiceItemPrice";

export default function InvoiceItemsPrice({
  selected,
  currency,
  exchangeRate,
}: any) {
  const available = selected.length;

  const data = new Array(28).fill({ id: 1 });
  let few = slice(data, 0, 28 - available);

  return (
    <div style={{ height: "100%" }} className="mt-2  border border-black">
      <div className="grid grid-cols-12 border-b  border-black  bg-gray-300">
        <div className="col-span-6 px-1 py-1 font-bold  text-black  text-left">
          Description of Good / Services
        </div>
        <div className="col-span-1 px-1  py-1 font-bold  text-black text-center">
          Qty
        </div>
        <div className="col-span-2 px-1  py-1 font-bold  text-black text-center">
          Unit Price
        </div>
        <div className="col-span-1 px-1  py-1 font-bold border-r border-black  text-black text-center">
          Unit
        </div>
        <div className="col-span-2 px-1  py-1 font-bold  text-black text-right">
          Net Total {currency}
        </div>
      </div>
      <div className="">
        {selected.map((item: any, index: number) => (
          <InvoiceItemPrice
            style={{ color: "red" }}
            key={index}
            index={index}
            item={item}
            exchangeRate={exchangeRate}
          />
        ))}

        {few.map((item: any, index: number) => (
          <div className="grid grid-cols-12 items-center" key={index}>
            <div className="col-span-6 px-1 text-white    text-center">-</div>
            <div className="col-span-1 px-1 text-white text-right">-</div>
            <div className="col-span-2 px-1 text-white text-right">-</div>
            <div className="col-span-1 px-1 border-r border-black text-white text-right">
              -
            </div>
            <div className="col-span-2 px-1 text-white text-right">-</div>
          </div>
        ))}
      </div>
    </div>
  );
}
