import { Dropdown, Menu, MenuProps, message } from "antd";
import { useRef, useState } from "react";
import fetchDataArps from "../../services/fetchDataArps";
import formatMoney from "../../utils/formatMoney";
import ReactToPrint from "react-to-print";
import spin from "../../assets/spin.svg";
import { MdCorporateFare } from "react-icons/md";

import {
  AiOutlineCheck,
  AiOutlinePrinter,
  AiOutlineSync,
} from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { BiLoader } from "react-icons/bi";
import postDataArps from "../../services/postDataArps";
import TransferDocumentMain from "./components/transfer/TransferDocumentMain";
import TransferDocumentPrice from "./components/transfer/TransferDocumentPrice";
import ValueHead from "../../components/shared/ValueHead";

export default function Transfers() {
  const [entries, setEntries] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSyncing, setSyncing] = useState<boolean>(false);
  const [wip, setWip] = useState("");
  const [tin, setTin] = useState("");
  const [lpo, setLpo] = useState("");
  const [vrn, setVrn] = useState("");
  const [dln, setDln] = useState("");
  const [invoice, setInvoice] = useState("");
  const [company, setCompany] = useState<any>({
    label: "Karimjee Value Chain",
    key: 20,
    address1: "Sokoine Drive, P.o Box 409",
    address2: "Dar es salaam",
    address3: "Tel : 051 866815-9, 866352",
    address4: "Dar es salaam",
    tin: "TIN : 106-207-674",
    vrn: "VRN : 40-023170-U",
  });
  const docRef = useRef(null);
  const [isAllSelected, setAllSelected] = useState(false);

  const handleSyncData = async () => {
    setSyncing(true);
    const uri = `revenue/parts/upload`;
    const data = {};
    const response = await postDataArps(data, uri);
    if (response.success) {
      message.success("Data synched successfully");
    } else {
      message.error("Something went wrong");
    }
    setSyncing(false);
  };

  const handleFetchByWip = async () => {
    setSelected([]);
    setLoading(true);
    const uri = `revenue/parts/search?wip=${wip}&invoice=${invoice}&company=${company?.key}`;
    const response = await fetchDataArps(uri);

    if (response.success === false) {
      message.error(response.message);
      setLoading(false);
      return;
    }
    if (response.payload.length === 0) {
      message.error(`No Records Found`);
      setEntries([]);
      setSelected([]);
      setLoading(false);
      return;
    }
    setEntries(response.payload);

    setLoading(false);
  };

  const handleClearState = async () => {
    setWip("");
    setTin("");
    setVrn("");
    setLpo("");
    setDln("");
    setInvoice("");
    setEntries([]);
    setSelected([]);
    setLoading(false);
    setSyncing(false);
    setAllSelected(false);
    setCompany({
      label: "Karimjee Value Chain",
      key: 20,
    });
  };

  const handleAddItem = (item: any) => {
    setSelected([...selected, item]);
  };

  const handleRemoveItem = (item: any) => {
    var filtered = selected.filter(function (value: any) {
      return value._id !== item?._id;
    });
    setSelected(filtered);
  };

  const handleAddAll = () => {
    setSelected(entries);
    setAllSelected(true);
  };
  const handleRemoveAll = () => {
    setSelected([]);
    setAllSelected(false);
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const com = companies?.filter((item: any) => item?.key === e.key);
    setCompany(com[0]);
  };

  const companies = [
    {
      key: "20",
      label: "Karimjee Value Chain",
      address1: "Sokoine Drive, P.o Box 409",
      address2: "Dar es salaam",
      address3: "Tel : 051 866815-9, 866352",
      address4: "Dar es salaam",
      tin: "TIN : 106-207-674",
      vrn: "VRN : 40-023170-U",
    },
  ];

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: "Karimjee Value Chain",
          key: "20",
          icon: <MdCorporateFare />,
        }
      ]}
    />
  );

  return (
    <>
    <ValueHead />
      <div
        style={{ fontSize: "14px" }}
        className="grid grid-cols-12 gap-0 mx-4 my-2 border rounded-lg"
      >
        <div className="bg-white col-span-2 rounded-l-lg border-r p-4">
          <input
            type="number"
            onChange={(e) => {
              setWip(e.target.value);
            }}
            value={wip}
            placeholder="Enter WIP Number"
            className="border border-gray-400 p-2 rounded-md w-full justify-center items-start focus:border-toyota focus:outline-none"
          />
          <input
            type="number"
            onChange={(e) => {
              setInvoice(e.target.value);
            }}
            value={invoice}
            placeholder="Enter Invoice No."
            className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
          />

          <div className="">
            <Dropdown.Button overlay={menu} className="mt-2" style={{}}>
              <div className="flex justify-center items-center truncate -ml-2">
                <MdCorporateFare />
                <div className="ml-2 truncate">{company?.label}</div>
              </div>
            </Dropdown.Button>
          </div>
          <div className="flex border-t my-4">
            <button
              onClick={() => handleFetchByWip()}
              className="p-2 bg-toyota hover:bg-blue-900 text-white my-2 mr-1 rounded-md w-full hover:text-white"
            >
              {isLoading ? (
                <div className="flex justify-center items-center pb-1">
                  <img src={spin} alt="spin" height="23px" width="23px" />
                </div>
              ) : (
                <div className=" flex justify-center items-center pb-1">
                  Submit
                </div>
              )}
            </button>
            <button
              onClick={() => handleClearState()}
              className="p-2 bg-white border border-gray-300 hover:border-toyota hover:text-toyota text-gray-800 my-2 ml-1 rounded-md w-full "
            >
              <div className="pb-1">Reset</div>
            </button>
          </div>

          <div className="mb-4">
 
            <input
              type="text"
              onChange={(e) => {
                setLpo(e.target.value);
              }}
              value={lpo}
              placeholder="Enter LPO Number."
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />

          </div>
          <div className="">
            {entries.length === 0 ? null : (
              <button
                onClick={() => {
                  isAllSelected ? handleRemoveAll() : handleAddAll();
                }}
                className={` flex items-center px-2 py-2  mb-1 w-full border border-gray-300 p-2  hover:border-green-500  ${
                  isAllSelected ? "bg-green-100 " : "hover:bg-gray-100 "
                }`}
              >
                {isAllSelected ? (
                  <button
                    className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                    onClick={() => handleRemoveAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                ) : (
                  <button
                    className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-gray-500"
                    onClick={() => handleAddAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                )}
                <div className="ml-2">Select All</div>
              </button>
            )}
            {entries?.map((entry: any, index: number) => (
              <>
                <button
                  className={`border border-gray-300 p-2 mb-2 w-full hover:border-green-500  ${
                    selected?.includes(entry)
                      ? "bg-green-100 "
                      : "hover:bg-gray-100 "
                  }`}
                  onClick={() =>
                    selected?.includes(entry)
                      ? handleRemoveItem(entry)
                      : handleAddItem(entry)
                  }
                  key={index}
                >
                  <div className="flex">
                    <div className="">
                      {selected?.includes(entry) ? (
                        <button
                          className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                          onClick={() => handleRemoveItem(entry)}
                        >
                          <AiOutlineCheck color="#FFFFFF" />
                        </button>
                      ) : (
                        <button
                          className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-gray-500"
                          onClick={() => handleAddItem(entry)}
                        >
                          <AiOutlineCheck color="#FFFFFF" />
                        </button>
                      )}
                    </div>
                    <div className="ml-2">
                      <span className="font-bold mr-1">Part no:</span>
                      {entry?.part_number}
                    </div>
                  </div>
                  <div className="my-2 text-left">{entry?.description}</div>
                  <div className="border-t-2 border-gray-500 mt-2 font-bold flex justify-end">
                    {formatMoney(entry?.net_sale ? Number(entry?.net_sale) : 0)}
                  </div>
                </button>
              </>
            ))}
          </div>
        </div>
        <div className="bg-gray-50 col-span-10 h-full rounded-r-lg">
          <div className="flex">
            <div className="w-full">
              <div className="">
                <div className="py-3 px-8  border-b flex justify-between items-center bg-white rounded-t-lg">
                  <div className="">STOCK TRANSFER NOTE</div>
                  <div className="flex">
                    <button
                      onClick={() => handleSyncData()}
                      className="flex justify-center items-center border p-2 mr-2 text-sm"
                    >
                      <div className="flex justify-center items-center hover:text-toyota">
                        <div className="mr-1">
                          {isSyncing ? <BiLoader /> : <AiOutlineSync />}
                        </div>
                        <div>Sync Data</div>
                      </div>
                    </button>
                    {selected.length === 0 ? (
                      <button className="flex cursor-not-allowed justify-center border p-2 items-center focus:outline-none  mr-2 text-sm">
                        <AiOutlinePrinter className="mr-1" /> Print Invoice
                      </button>
                    ) : (
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <button className="flex justify-center border p-2 items-center focus:outline-none  mr-2 text-sm hover:text-toyota">
                              <AiOutlinePrinter className="mr-1" /> Print
                              Invoice
                            </button>
                          );
                        }}
                        content={() => docRef.current}
                        documentTitle={`Transfer`}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                {selected.length === 0 ? (
                  <div className="h-screen">
                    <div className="flex flex-col justify-center items-center mt-32">
                      <div className="">
                        <BsSearch color="#9ca3af" />
                      </div>
                      <div className="text-gray-400">Nothing to preview</div>
                    </div>
                  </div>
                ) : (
                  <div className="rounded-r-lg" ref={docRef}>
                    <TransferDocumentMain
                      isGatePass={false}
                      company={company}
                      fields={{ tin: tin, vrn: vrn, lpo: lpo, dln: dln }}
                      selected={selected}
                    />
                    <TransferDocumentPrice
                      selected={selected}
                      fields={{ tin: tin, vrn: vrn, lpo: lpo, dln: dln }}
                      company={company}
                    />
                    <TransferDocumentMain
                      isGatePass={true}
                      fields={{ tin: tin, vrn: vrn, lpo: lpo, dln: dln }}
                      selected={selected}
                      company={company}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
