import splitIntoChunks from "../../../../utils/splitIntoChunks";
import PrintWrapperMain from "./PrintWrapperMain";
import "./print.css";

export default function PrintDocumentMain({
  selected,
  isGatePass,
  company,
  tin,
  lpo,
  address1,
  address2,
}: any) {
  const chunks = splitIntoChunks(selected, 28);
  return (
    <div className="">
      {chunks.map((page: any, index: number) => (
        <div className="page bg-white" key={index}>
          <div className="subpage bg-white">
            <PrintWrapperMain
              selected={page}
              data={selected}
              pageno={index + 1}
              pages={chunks.length}
              totalItems={selected?.length}
              isGatePass={isGatePass}
              company={company}
              tin={tin}
              lpo={lpo}
              address1={address1}
              address2={address2}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
