import { ARPS_BASE_URL } from "../utils/urls";

const fetchDataArps = async (uri: any) => {
   const response = await fetch(`${ARPS_BASE_URL}/${uri}`, {
     method: "GET",
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
     },
   });
  return await response.json();
};

export default fetchDataArps;
