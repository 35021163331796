import moment from "moment";

export default function TransferCust({ selected }: any) {
  return (
    <div className="flex mt-1">
      <div className="w-2/5 border border-black">
        <div className="bg-gray-300 border-b border-black px-2 py-1 font-bold">
          Name & Address
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col px-2 flex-1 mb-6">
            <span>{selected[0]?.customer}</span>
            <span>{selected[0]?.address1}</span>
            <span>{selected[0]?.address2}</span>
            <span>{selected[0]?.address3}</span>
            <span>{selected[0]?.address4}</span>
          </div>
        </div>
      </div>
      <div className="w-1/5"></div>
      <div className="w-2/5 border-t border-l border-r border-black font-bold">
        <div className="px-2 pb-1 text-lg border-b border-black text-center">
          DEBIT NOTE
        </div>
        <div className="flex justify-between border-b border-black">
          <div className="w-32 border-r border-black px-2 py-1">
            Document Number
          </div>
          <div className="px-2 py-1">{selected[0]?.document_no}</div>
        </div>
        <div className="flex justify-between border-b border-black">
          <div className="w-32 border-r border-black px-2 py-1">
            Taxpoint Date
          </div>
          <div className="px-2 py-1">
            {moment(selected[0]?.date.toString()).format("DD/MM/YYYY")}
          </div>
        </div>
        <div className="flex justify-between border-b border-black">
          <div className="w-32 border-r border-black px-2 py-1">
            Account number
          </div>
          <div className="px-2 py-1">{selected[0]?.account}</div>
        </div>
        <div className="flex justify-between border-b border-black">
          <div className="w-32 border-r border-black px-2 py-1">Terms</div>
          <div className="px-2 py-1">-</div>
        </div>
        <div className="flex justify-between border-b border-black">
          <div className="w-32 border-r border-black px-2 py-1">Reference</div>
          <div className="px-2 py-1">-</div>
        </div>
      </div>
    </div>
  );
}
