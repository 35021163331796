import logo from "../../../assets/images/valuechain.png";
import toyota from "../../../assets/images/logo.png"
export default function TransferHead({ company }: any) {

  return (
    <div
      className="flex justify-between mb-6"
      style={{ fontSize: 12 }}
    >
      <div className="w-2/4">
        <img className="mx-auto max-h-28 "   src={company?.key == '20' ? logo : toyota } alt="" />
      </div>
      <div
        className="flex flex-col justify-end font-bold w-2/4 text-right"
        style={{ fontWeight: "bold" }}
      >
        <span style={{ fontSize: 15 }}>{company?.label}</span>
        <span>{company?.address1}</span>
        <span>{company?.address2}</span>
        <span>{company?.address3}</span>
        <span>{company?.address4}</span>
        <span>{company?.address5}</span>
        <span style={{ marginTop: 2 }}>
          TIN: {company?.tin}
        </span>
        <span>VRN: {company?.vrn}</span>
      </div>
    </div>
  );
}
