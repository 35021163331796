const operators: any = {
  " 1 ": "Mumba",
  " 2 ": "Msei",
  " 3 ": "Victor",
  " 4 ": "Juliana Sendwa",
  " 5 ": "Fatuma Suleiman",
  " 6 ": "Anna Chaulo",
  " 7 ": "Msafiri",
  " 8 ": "Amit",
  " 9 ": "Abed",
  " 10 ": "zmmbando",
  " 11 ": "Caren",
  " 113 ": "Samweli Kitomari",
  " 69 ": "Anna Mzori",
  " 104 ": "Noela Soka",
  " 27 ": "Diana Laizer",
  " 48 ": "Consolata Begina",
  " 112 ": "Ruth Kulindwa",
  "0113": "Samweli Kitomari",
  "0069": "Anna Mzori",
  "0005": "Fatuma Suleiman",
  "0104": "Noela Soka",
  "0006": "Anna Chaulo",
  "0027": "Diana Laizer",
  "0004": "Juliana Sendwa",
  "0048": "Consolata Begina",
  "0112": "Ruth Kulindwa",
  "0008": "Amit",
  "0009": "Abeid",
  "0010": "zmmbando",
  "0013": "Rukia",
  "0014": "Fransie",
  "0015": "Alinda",
  "0016": "Magayane",
  "0019": "Divesh",
  "0020": "Peter",
  "0022": "Bakari Miraji",
  "0024": "anold",
  "0025": "Korosho",
  "0026": "gloria barnabas",
  "0028": "Khamisi",
  "0029": "Sandeep",
  "0030": "Khamisi",
  "0031": "Bakari",
  "0032": "Alphone",
  "0033": "Neema",
  "0034": "",
  "0035": "Charles",
  "0036": "Catherine GGM",
  "0037": "Malele",
  "0038": "Malele GGM",
  "0039": "Catherine",
  "0040": "Kissui",
  "0044": "Lugonda",
  "0045": "Anold Kaaya",
  "0046": "Anold GGM",
  "0047": "Zuhra Mohamed",
  "0049": "Mercy Msangi",
  "0051": "Mehul",
  "0052": "Nafisa Yusuph",
  "0053": "Jonathan",
  "0054": "Stanley",
  "0059": "Rajaram",
  "0070": "Sophia Pangamawe",
  "0071": "Anam",
  "0077": "Brian",
  "0081": "Liberatus",
  "0088": "Judith",
  "0095": "MKazembe",
  "0101": "Isihaka",
  "0114": "Bakarinm",
  "0115": "Bakari Buly",
  "0116": "CathNorMara",
  "0117": "CathBuly",
  "0118": "SNortMara",
  "0119": "SBulyahul",
};

export { operators };
