export default function TransferTitle() {
  return (
    <div className="flex mb-2">
      <div className="w-1/3"></div>
      <div
        style={{ fontSize: "15px" }}
        className="w-2/3 border border-black py-1 font-bold px-8"
      >
        ACCOUNT-TAX INVOICE
      </div>
    </div>
  );
}
