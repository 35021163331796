export default function InvoiceCust({
  item,
  name,
  address1,
  address2,
  tin,
  vrn,
}: any) {
  return (
    <>
      <div className="flex flex-col">
        <div className="font-bold my-2 ">TO:</div>
        <div className="flex flex-col">
          <div className="border-t border-r border-l border-black text-black px-1 pb-1 w-56 tracking-wider">
            {item?.customer !== " " ? item?.customer : name}
          </div>
          <div className="border-t border-r border-l  border-black text-black px-1 pb-1 w-56 tracking-wider">
            {item?.address1 !== " " ? item?.address1 : address1}
          </div>
          <div className="border-t border-r border-l  border-black text-black px-1 pb-1 w-56 tracking-wider">
            {item?.address2 !== " " ? item?.address2 : address2}
          </div>
          <div className="border-t border-r border-l  border-black text-black px-1 pb-1 w-56 tracking-wider">
            {item?.address3 !== " " ? item?.address3 : tin}
          </div>
          <div className="border border-black text-black px-1 pb-2 w-56 tracking-wider">
            {item?.address4 !== " " ? item?.address4 : vrn}
          </div>
        </div>
      </div>
    </>
  );
}
