import { Outlet } from "react-router";
import Header from "../components/layouts/Header";
import { TbTransferOut } from "react-icons/tb";
import {
  AiOutlineCar,
  AiOutlineFileSearch,
  AiOutlineFileText,
} from "react-icons/ai";
import { 
  IoCashOutline
} from "react-icons/io5"
import AdminLink from "../components/shared/AdminLink";

export default function MainLayout() {
  const menus = [
    {
      id: 1,
      to: "/logistics",
      name: "Logistics",
      icon: <AiOutlineCar size={28} />,
    },
    {
      id: 2,
      to: "/transfers/note",
      name: "Transfers",
      icon: <TbTransferOut size={28} />,
    },

    {
      id: 3,
      to: "/value/chain/transfer",
      name: "KVCL",
      icon: <AiOutlineFileText size={28} />,
    },
    {
      id: 4,
      to: "/relief/invoice",
      name: "Relief Invoice",
      icon: <AiOutlineFileText size={28} />,
    },
    {
      id: 5,
      to: "/toyota/invoice/vmi",
      name: "VMI Invoice",
      icon: <AiOutlineFileSearch size={28} />,
    },
    {
      id: 6,
      to: "/vmi/statement",
      name: "Statement",
      icon: <IoCashOutline size={28} />,
    },  
  ];
  return (
    <div className="flex bg-gray-100 h-screen font-toyota">
      <div className="w-full">
        <Header />
        <div className="flex">
          <div className="bg-gray-200 w-24 border-r border-gray-300">
            <div className="mt-0">
              {menus.map((menu: any, index: number) => (
                <AdminLink to={`${menu.to}`} icon={menu.icon} key={index}>
                  {menu.name}
                </AdminLink>
              ))}
            </div>
          </div>
          <div className="flex-1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
