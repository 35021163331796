import * as React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";


/*
|---------------------------------------------------------------------
|                             Main
|---------------------------------------------------------------------
*/
import AuthLayout from "./layouts/AuthLayout";
import HomeLayout from "./layouts/HomeLayout";
import MainLayout from "./layouts/MainLayout";
import Signin from "./pages/auth/Signin";

import "antd/dist/antd.min.css";
import "./App.css";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import Transfers from "./pages/transfers/Transfers";
import VLCTransfers from "./pages/value/Transfers";
import Logistics from "./pages/logistics/Logistics";
import Toyota from "./pages/toyota/Toyota";
import Debitnote from "./pages/debitnote/Debitnote";
import Receipt from "./pages/receipt/Receipt";
import Soon from "./pages/soon";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";
import Values from "./pages/value/Values";
import Relief from "./pages/relief/Relief";
import Page404 from "./pages/errors/Page404";
import Statement from "./pages/statements/Statement";
import Mbeya from "./pages/mbeya/Mbeya";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Signin />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
        </Route>
        <Route element={<HomeLayout />}></Route>
        <Route element={<MainLayout />}>
          <Route
            path="/logistics"
            element={
              <RequireAuth>
                <Logistics />
              </RequireAuth>
            }
          />
          <Route
            path="/vmi/statement"
            element={
              <RequireAuth>
                <Statement />
              </RequireAuth>
            }
          />
          <Route
            path="/logistics/invoice"
            element={
              <RequireAuth>
                <Logistics />
              </RequireAuth>
            }
          />
          <Route
            path="/logistics/debitnote"
            element={
              <RequireAuth>
                <Debitnote />
              </RequireAuth>
            }
          />
          <Route
            path="/logistics/creditnote"
            element={
              <RequireAuth>
                <Soon />
              </RequireAuth>
            }
          />
          <Route
            path="/logistics/receipt"
            element={
              <RequireAuth>
                <Receipt />
              </RequireAuth>
            }
          />
          <Route
            path="/transfers/note"
            element={
              <RequireAuth>
                <Transfers />
              </RequireAuth>
            }
          />

          <Route
            path="/value/chain/transfer"
            element={
              <RequireAuth>
                <VLCTransfers />
              </RequireAuth>
            }
          />

<Route
            path="/value/chain/invoice"
            element={
              <RequireAuth>
                <Values />
              </RequireAuth>
            }
          />

          <Route
            path="/relief/invoice"
            element={
              <RequireAuth>
                <Relief />
              </RequireAuth>
            }
          />
          <Route
            path="/toyota/invoice/vmi"
            element={
              <RequireAuth>
                <Toyota />
              </RequireAuth>
            }
          />
          <Route
            path="/toyota/invoice/mbeya"
            element={
              <RequireAuth>
                <Mbeya />
              </RequireAuth>
            }
          />

        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
    </AuthProvider>
  );
}


function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = React.useContext(AuthContext);
  let location = useLocation();

  if (!auth.token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
