import React from "react";
import { useNavigate } from "react-router";

export default function Page404() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center mt-24">
        <div className="text-6xl text-toyota">404</div>
        <div className="mt-2">Page Not Found</div>

        <button
          onClick={() => navigate(-1)}
          className="border bg-toyota text-white px-8 py-2 mt-4 rounded-md text-xs"
        >
          Go Back
        </button>
      </div>
    </div>
  );
}
