import { message } from "antd";
import moment from "moment";
import { useRef, useState } from "react";
import fetchDataArps from "../../services/fetchDataArps";
import formatMoney from "../../utils/formatMoney";
import InvoiceWrapper from "./components/InvoiceWrapper";
import spin from "../../assets/spin.svg";
import { AiOutlineCheck, AiOutlinePrinter } from "react-icons/ai";
import { BsCheck2, BsSearch } from "react-icons/bs";
import { AiOutlineSync } from "react-icons/ai";
import { BiLoader } from "react-icons/bi";
import postDataArps from "../../services/postDataArps";

import { jsPDF } from "jspdf";
import PrintInvoiceWrapper from "./print/InvoiceWrapper";
import LogisticsHead from "../../components/shared/LogisticsHead";

export default function Logistics() {
  const [entries, setEntries] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSyncing, setSyncing] = useState<boolean>(false);
  const [wip, setWip] = useState("");
  const [invoice, setInvoice] = useState("");
  const [exchangeRate, setExchangeRate] = useState("1");
  const [currency, setCurrency] = useState("TZS");
  const [isAllSelected, setAllSelected] = useState(false);
  const docRef: any = useRef(null);
  const [isNotTZS, setNotTZS] = useState<boolean>(false);
  const [addCustomer, setAddCustomer] = useState<boolean>(false);
  const [isExempted, setExempted] = useState<boolean>(false);
  const [canPrint, setPrint] = useState(false);
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [tin, setTin] = useState("");
  const [vrn, setVrn] = useState("");

  const invRef: any = useRef(null);
  const handleFetchByWip = async () => {
    setSelected([]);
    setLoading(true);
    const uri = `service/search?wip=${wip}&invoice=${invoice}`;
    const response = await fetchDataArps(uri);

    if (response.success === false) {
      message.error(response.message);
      setLoading(false);
      return;
    }
    if (response.payload.length === 0) {
      message.error(`No Records Found`);
      setLoading(false);
      return;
    }
    setEntries(response.payload);

    setLoading(false);
  };

  const rates: any = { TZS: 1, USD: 2330, EUR: 2364, GBP: 2810 };

  const testPrint = () => {
    // 'p', 'px',
    setPrint(true);
    const doc: jsPDF | any = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: "smart", // or "smart", default is 16
    });

    if (docRef.current) {
      docRef.current.style.backgroundColor = "#FFFFFF";
      doc.html(docRef.current, {
        callback: function (doc: any) {
          doc.save(`Invoice-${selected[0]?.invoice}.pdf`);
          setPrint(false);
        },
        x: 30,
        y: 30,
        margin: [0, 50, 0, 0],
        width: 595.28,
      });
    }
  };

  const handleSyncData = async () => {
    setSyncing(true);
    const uri = `service/vlc`;
    const data = {};
    const response = await postDataArps(data, uri);
    if (response.success) {
      message.success("Data synched successfully");
    } else {
      message.error("Something went wrong");
    }
    setSyncing(false);
  };

  const handleClearState = async () => {
    setWip("");
    setInvoice("");
    setEntries([]);
    setSelected([]);
    setLoading(false);
    setSyncing(false);
    setAllSelected(false);
    setNotTZS(false);
    setCurrency("TZS");
    setExchangeRate(rates["TZS"]);
    setName("");
    setAddress1("");
    setAddress2("");
    setAddCustomer(false);
    setTin("");
    setVrn("");
  };

  const handleAddItem = (item: any) => {
    setSelected([...selected, item]);
  };

  const handleCurrencyChange = (e: any) => {
    const currency = e;
    setExchangeRate(rates[currency]);
    if (currency === "TZS") {
      setNotTZS(false);
    } else {
      setNotTZS(true);
    }
    setCurrency(currency);
  };

  const handleRemoveItem = (item: any) => {
    var filtered = selected.filter(function (value: any, index: any, arr: any) {
      return value._id !== item?._id;
    });
    setSelected(filtered);
  };

  const handleAddAll = () => {
    var filtered = entries.filter(function (value: any, index: any, arr: any) {
      return value.invoice_value > 0;
    });
    setSelected(filtered);
    setAllSelected(true);
  };

  const handleRemoveAll = () => {
    setSelected([]);
    setAllSelected(false);
  };

  return (
    <>
      <LogisticsHead />
      <div
        style={{ fontSize: "14px" }}
        className="grid grid-cols-12 gap-0 mx-4 my-2 border rounded-lg"
      >
        <div className="bg-white col-span-2 rounded-l-lg border-r p-4">
          <input
            type="number"
            onChange={(e) => {
              setWip(e.target.value);
            }}
            value={wip}
            placeholder="Enter WIP Number"
            className="border border-gray-400 p-2 rounded-md w-full justify-center items-start focus:border-toyota focus:outline-none"
          />
          <input
            type="number"
            onChange={(e) => {
              setInvoice(e.target.value);
            }}
            value={invoice}
            placeholder="Enter Invoice No."
            className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
          />

          <div className="flex border-t my-4">
            <button
              onClick={() => handleFetchByWip()}
              className="p-2 bg-toyota hover:bg-blue-900 text-white my-2 mr-1 rounded-md w-full hover:text-white"
            >
              {isLoading ? (
                <div className="flex justify-center items-center pb-1">
                  <img src={spin} alt="spin" height="23px" width="23px" />
                </div>
              ) : (
                <div className=" flex justify-center items-center pb-1">
                  Submit
                </div>
              )}
            </button>
            <button
              onClick={() => handleClearState()}
              className="p-2 bg-white border border-gray-300 hover:border-toyota hover:text-toyota text-gray-800 my-2 ml-1 rounded-md w-full "
            >
              <div className="pb-1">Reset</div>
            </button>
          </div>


          <button
            onClick={() => setExempted(!isExempted)}
            className="py-2 flex justify-between w-full"
          >
            <div className="">VAT Exempted</div>
            <div
              className={`h-6 w-6 border flex justify-center items-center border-gray-500 ${
                isExempted ? " bg-green-600" : ""
              }`}
            >
              {isExempted ? (
                <BsCheck2 color={"#FFFFFF"} />
              ) : (
                ""
              )}
            </div>
          </button>

          <button
            onClick={() => setAddCustomer(!addCustomer)}
            className="py-2 flex justify-between w-full"
          >
            <div className="">Customer Details</div>
            <div
              className={`h-6 w-6 border flex justify-center items-center border-gray-500 ${
                addCustomer ? " bg-green-600" : ""
              }`}
            >
              {addCustomer ? (
               <BsCheck2 color={"#FFFFFF"} />
              ) : (
                ""
              )}
            </div>
          </button>

          {addCustomer && (
            <div className="mb-4">
              <input
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                placeholder="Enter Name"
                className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
              />
              <input
                type="text"
                onChange={(e) => {
                  setAddress1(e.target.value);
                }}
                value={address1}
                placeholder="Enter Address 1"
                className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
              />

              <input
                type="text"
                onChange={(e) => {
                  setAddress2(e.target.value);
                }}
                value={address2}
                placeholder="Enter Address 2"
                className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
              />
              <input
                type="text"
                onChange={(e) => {
                  setTin(e.target.value);
                }}
                value={tin}
                placeholder="Enter TIN No."
                className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
              />

              <input
                type="text"
                onChange={(e) => {
                  setVrn(e.target.value);
                }}
                value={vrn}
                placeholder="Enter VRN No."
                className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
              />
            </div>
          )}

          <div className="flex flex-col">
            <span>Currency</span>
            <select
              className="border border-gray-400 p-1 rounded-md mb-2 mt-1 w-full"
              onChange={(e) => handleCurrencyChange(e.target.value)}
              value={currency}
            >
              <option selected hidden>
                {currency}
              </option>
              <option value="TZS">TZS</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
            </select>
            <div>
              {isNotTZS ? (
                <div>
                  <span>Exchange Rate</span>
                  <input
                    type="number"
                    onChange={(e) => {
                      setExchangeRate(e.target.value);
                    }}
                    value={exchangeRate}
                    placeholder="Exchange rate"
                    className="border border-gray-400 p-2 rounded-md w-full mb-4 justify-center items-start focus:border-toyota focus:outline-none"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="">
            {entries.length === 0 ? null : (
              <button
                onClick={() => {
                  isAllSelected ? handleRemoveAll() : handleAddAll();
                }}
                className={` flex items-center px-2 py-2  mb-1 w-full border border-gray-300 p-2  hover:border-green-500  ${
                  isAllSelected ? "bg-green-100 " : "hover:bg-gray-100 "
                }`}
              >
                {isAllSelected ? (
                  <button
                    className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                    onClick={() => handleRemoveAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                ) : (
                  <button
                    className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-gray-500"
                    onClick={() => handleAddAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                )}
                <div className="ml-2">Select All</div>
              </button>
            )}
            {entries?.map((entry: any, index: number) => (
              <>
                {entry?.invoice_value < 0 ? null : (
                  <button
                    className={`border border-gray-300 p-2 mb-2 w-full hover:border-green-500  ${
                      selected?.includes(entry)
                        ? "bg-green-100 "
                        : "hover:bg-gray-100 "
                    }`}
                    onClick={() =>
                      selected?.includes(entry)
                        ? handleRemoveItem(entry)
                        : handleAddItem(entry)
                    }
                    key={index}
                  >
                    <div className="flex">
                      <div className="">
                        {selected?.includes(entry) ? (
                          <button
                            className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                            onClick={() => handleRemoveItem(entry)}
                          >
                            <AiOutlineCheck color="#FFFFFF" />
                          </button>
                        ) : (
                          <button
                            className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-gray-500"
                            onClick={() => handleAddItem(entry)}
                          >
                            <AiOutlineCheck color="#FFFFFF" />
                          </button>
                        )}
                      </div>
                      <div className="ml-2">
                        {moment(entry?.invoice_date.toString()).format(
                          "Do MMM YYYY"
                        )}
                      </div>
                    </div>
                    <div className="my-2 text-left text-xs">
                      Invoice No: {entry?.invoice}
                    </div>
                    <div className="my-2 text-left">
                      {entry?.service_details}
                    </div>
                    <div className="border-t-2 border-gray-500 mt-2 font-bold flex justify-end">
                      {formatMoney(
                        entry?.invoice_value
                          ? Number(entry?.invoice_value / Number(exchangeRate))
                          : 0
                      )}
                    </div>
                  </button>
                )}
              </>
            ))}
          </div>
        </div>
        <div className="bg-gray-50 col-span-10 h-full rounded-r-lg">
          <div className="flex">
            <div className="w-full">
              <div className="">
                <div className="py-3 px-8 border-b flex justify-between items-center bg-white rounded-t-lg">
                  <div className="">LOGISTICS : INVOICE</div>
                  <div className="flex">
                    <button
                      onClick={() => handleSyncData()}
                      className="flex justify-center items-center border p-2 mr-2 text-sm"
                    >
                      <div className="flex justify-center items-center hover:text-toyota">
                        <div className="mr-1">
                          {isSyncing ? <BiLoader /> : <AiOutlineSync />}
                        </div>
                        <div>Sync Data</div>
                      </div>
                    </button>
                    {selected.length === 0 ? (
                      <button className="flex cursor-not-allowed justify-center border px-2 py-1 items-center focus:outline-none  mr-2 text-sm">
                        <AiOutlinePrinter className="mr-1" /> Print Invoice
                      </button>
                    ) : (
                      <button
                        className="flex justify-center border px-2 py-1 items-center focus:outline-none  mr-2 text-sm"
                        onClick={testPrint}
                      >
                        {canPrint ? <BiLoader /> : <AiOutlinePrinter />}
                        <span className="ml-1">Print Invoice</span>
                      </button>
                    )}
                  </div>
                </div>
                {selected.length === 0 ? (
                  <div className="h-screen">
                    <div className="flex flex-col justify-center items-center mt-32">
                      <div className="">
                        <BsSearch color="#9ca3af" />
                      </div>
                      <div className="text-gray-400">Nothing to preview</div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="rounded-r-lg">
                      <InvoiceWrapper
                        entries={entries}
                        currency={currency}
                        name={name}
                        address1={address1}
                        address2={address2}
                        tin={tin}
                        vrn={vrn}
                        exchangeRate={exchangeRate}
                        selected={selected}
                        isExempted={isExempted}
                        isLoading={isLoading}
                      />
                    </div>

                    <div className={`${canPrint ? "block" : "hidden"}`}>
                      <PrintInvoiceWrapper
                        id="print"
                        docRef={docRef}
                        ref={invRef}
                        currency={currency}
                        name={name}
                        address1={address1}
                        address2={address2}
                        tin={tin}
                        vrn={vrn}
                        exchangeRate={exchangeRate}
                        entries={entries}
                        selected={selected}
                        isExempted={isExempted}
                        isLoading={isLoading}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
