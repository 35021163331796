import logo from "../../../../assets/images/kvclogo2.png";
import qrcode from "../../../../assets/images/qrcode.png";

export default function TransferHead({ company }: any) {
  return (
    <div
      className="flex justify-between items-center mb-2 w-full"
      style={{ fontSize: 10 }}
    >
      <div className="w-2/6">
        <img className="mx-auto" src={qrcode} alt="" width={70} height={40} />
        <div className="flex flex-col items-center">
          <div className="font-bold">8001 1277</div>
          <div style={{ fontSize: 9 }}>KARIMJEE VALUE CHAIN</div>
        </div>
      </div>
      <div className="flex flex-col items-center w-2/6">
        {/* <div className="font-bold uppercase mb-2" style={{ fontSize: 10 }}>
          {company?.label}
        </div> */}
        <img
          className="mx-auto"
          style={{ marginLeft: -20 }}
          src={logo}
          width={340}
          height={170}
          alt=""
        />
      </div>
      <div style={{ fontSize: 10 }} className="flex flex-col font-bold w-2/6">
        <span className="text-md" style={{ fontSize: 12 }}>
          DAR ES SALAAM
        </span>
        <span>{company?.address1}</span>
        <span>{company?.address2}</span>
        {/* <span>{company?.address3}</span> */}
        {/* <span>{company?.address4}</span> */}
        <span className="mt-2">TIN: 106-207-674</span>
        <span>VRN: 40-023170-U</span>
      </div>
    </div>
  );
}
