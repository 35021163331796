import formatMoney from "../../../utils/formatMoney";

export default function InvoiceItemPrice({ item, index, exchangeRate }: any) {
  const { vat, description, quantity } = item;
  return (
    <>
      <div className="grid grid-cols-12 items-center" key={index}>
        <div className="col-span-6 border-r border-black px-1  text-left ">
          <div className="flex">
            <div className="ml-1">{description}</div>
          </div>
        </div>
        <div className="col-span-1  px-1 border-r border-black  text-black text-center">
          {vat}
        </div>
        <div className="col-span-1 border-r border-black px-1  text-center">
          {quantity}
        </div>
        <div className="col-span-2 border-r border-black px-1  text-black text-center">
          {formatMoney(
            item?.net_sale
              ? item?.net_sale / quantity / Number(exchangeRate)
              : 0
          )}
        </div>

        <div className="col-span-2 px-1 text-black text-right">
          {formatMoney(
            item?.net_sale ? item?.net_sale / Number(exchangeRate) : 0
          )}
        </div>
      </div>
    </>
  );
}
