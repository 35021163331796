import splitIntoChunks from "../../../../utils/splitIntoChunks";
import PreviewWrapperMain from "./PreviewWrapperMain";
import "./custom.css";

export default function PreviewDocumentMain({
  selected,
  isGatePass,
  company,
  tin,
  lpo,
  vrn,
  address1,
  address2,
}: any) {
  const chunks = splitIntoChunks(selected, 28);
  return (
    <div className="book">
      {chunks.map((page: any, index: number) => (
        <div className="page-transfer" key={index}>
          <div className="subpage-transfer">
            <PreviewWrapperMain
              selected={page}
              data={selected}
              pageno={index + 1}
              pages={chunks.length}
              totalItems={selected?.length}
              isGatePass={isGatePass}
              company={company}
              tin={tin}
              lpo={lpo}
              vrn={vrn}
              address1={address1}
              address2={address2}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
