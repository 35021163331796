import moment from "moment";
import formatMoney from "../../../utils/formatMoney";

export default function InvoiceItemPrice({ item, index, exchangeRate }: any) {
  const { reference, total_amount, invoice_date, invoice_number, document_type } = item;
  return (
    <>
      <div className="grid grid-cols-12 items-center" key={index}>
        <div className="col-span-2  px-1 text-black border-r border-black text-center">
          {moment(invoice_date,'YYYYMMDD').format("DD/MM/YYYY")}
        </div>
        <div className="col-span-1 px-1 text-black border-r border-black text-center">
          {document_type}
        </div>
        <div className="col-span-1 px-1 text-black border-r border-black text-center">
          {invoice_number}
        </div>
        <div className="col-span-3 px-1 border-r border-black text-black text-left">
          { reference }
        </div>
        <div className="col-span-2 px-1 text-black border-r border-black text-right">
        {formatMoney(total_amount ? total_amount / Number(exchangeRate) : 0.00)}
        </div>
        <div className="col-span-1 px-1 text-black border-r border-black text-right">0</div>
        <div className="col-span-2 px-1 text-black border-r border-black text-right">
          {formatMoney(total_amount ? total_amount / Number(exchangeRate) : 0)}
        </div>
        {/* <div className="col-span-6 px-1  text-left ">
          <div className="flex">
            <div className="w-14"></div>
            <div className="ml-8">{reference}</div>
          </div>
        </div>
        <div className="col-span-1 px-1  text-center">{quantity}</div>
        <div className="col-span-2 px-1  text-black text-center">
          {formatMoney(
            item?.total_amount
              ? item?.total_amount  / Number(exchangeRate)
              : 0
          )}
        </div>
        <div className="col-span-1 px-1 border-r border-black  text-black text-center">
          EA
        </div>
        <div className="col-span-2 px-1 text-black text-right">
          {formatMoney(
            item?.total_amount ? item?.total_amount / Number(exchangeRate) : 0
          )}
        </div> */}
      </div>
    </>
  );
}
