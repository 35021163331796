import TransferHead from "./TransferHead";
import TransferCust from "./TransferCust";
import InvoiceItemsPrice from "./InvoiceItemsPrice";

export default function TransferWrapperPrice({
  exchangeRate,
  selected,
  currency,
}: any) {
  return (
    <div id="transfer-doc" style={{ fontSize: "14px" }}>
      <div className="">
        <TransferHead />
        <TransferCust selected={selected} />
        <div style={{ height: "100%" }}>
          <InvoiceItemsPrice
            selected={selected}
            exchangeRate={exchangeRate}
            currency={currency}
          />
        </div>
        <div className="absolute bottom-0 right-0 left-0">
          <div className="my-12">Subject to realization of cheque</div>
          <div className="mb-8 flex">
            <div className="mr-4">Payment Received by:</div>
            <div className="">
              _____________________________________________________________
            </div>
          </div>
          <div className="">With Thanks</div>
          <div className="">For Toyota Tanzania Limited</div>
        </div>
      </div>
    </div>
  );
}
