import logo from "../../../../assets/images/kvclogo2.png";
import qrcode from "../../../../assets/images/qrcode.png";

export default function TransferHead({ company }: any) {
  return (
    <div
      className="flex justify-between items-center mb-2 w-full"
      style={{ fontSize: 10 }}
    >
      <div className="w-1/4">
        <img className="mx-auto h-24 w-32" src={qrcode} alt="" />
        <div className="flex flex-col items-center">
          <div className="font-bold">8001 1277</div>
          <div className="uppercase">Karimjee Value Chain</div>
        </div>
      </div>
      <div className="flex flex-col items-center w-2/4">
        <img
          className="mx-auto h-24"
          style={{ marginLeft: 10 }}
          src={logo}
          alt=""
        />
      </div>
      <div style={{ fontSize: 11 }} className="flex flex-col font-bold w-1/4">
        <span className="text-md" style={{ fontSize: 14 }}>
          DAR ES SALAAM
        </span>
        <span>{company?.address1}</span>
        <span>{company?.address2}</span>
        {/* <span>{company?.address3}</span> */}
        {/* <span>{company?.address4}</span> */}
        <span className="mt-2">TIN: 106-207-674</span>
        <span>VRN: 40-023170-U</span>
      </div>
    </div>
  );
}
