import { slice } from "lodash";
import InvoiceItem from "./InvoiceItem";

export default function InvoiceItems({
  selected,
  currency,
  exchangeRate,
}: any) {
  const available = selected.length;

  const data = new Array(24).fill({ id: 1 });
  let few = slice(data, 0, 24 - available);

  return (
    <div style={{ height: "100%" }} className="mt-2  border border-gray-400">
      <div className="grid grid-cols-12 border-b  border-black  bg-toyota">
        <div className="col-span-1 px-1 py-1 font-bold border-r border-gray-200 text-white  text-center">
          ITEM
        </div>
        <div className="col-span-5 px-1 py-1  font-bold border-r border-gray-200 text-white text-center">
          DESCRIPTION
        </div>

        <div className="col-span-3 px-1 py-1  font-bold border-r border-gray-200  text-white text-right">
          UNIT PRICE ( {currency} )
        </div>
        <div className="col-span-3 px-1  py-1 font-bold text-white text-right">
          TOTAL PRICE ( {currency} )
        </div>
      </div>
      <div className="">
        {selected.map((item: any, index: number) => (
          <InvoiceItem
            style={{ color: "red" }}
            key={index}
            index={index}
            item={item}
            exchangeRate={exchangeRate}
          />
        ))}

        {few.map((item: any, index: number) => (
          <div className="grid grid-cols-12 items-center" key={index}>
            <div className="col-span-1 px-1 border-r text-white  border-gray-400  text-center">
              -
            </div>
            <div className="col-span-5 px-1 border-r text-white border-gray-400  text-left ">
              -
            </div>
            <div className="col-span-3 px-1 border-r text-white border-gray-400 text-right">
              -
            </div>
            <div className="col-span-3 px-1 text-white border-gray-400 text-right">
              -
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
