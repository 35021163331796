import splitIntoChunks from "../../../../utils/splitIntoChunks";
import TransferWrapperPrice from "./TransferWrapperPrice";
import "./print.css";

export default function TransferDocumentPrint({
  selected,
  company,
  currency,
  docRef,
  exchangeRate,
  fields,
}: any) {
  const chunks = splitIntoChunks(selected, 28);
  return (
    <div className="book w-full" ref={docRef} style={{ fontSize: 10 }}>
      <div className=" bg-white">

      {chunks.map((page: any, index: number) => (
        <div className="subpage" style={{marginTop: 20 + index * 10}} key={index}>
          <TransferWrapperPrice
            selected={page}
            pageno={index + 1}
            fields={fields}
            pages={chunks.length}
            totalItems={selected}
            company={company}
            currency={currency}
            exchangeRate={exchangeRate}
            />
        </div>
      ))}
      </div>
    </div>
  );
}
