import { Dropdown, Menu, MenuProps, message } from "antd";
import { useRef, useState } from "react";
import { jsPDF } from "jspdf";
import fetchDataArps from "../../services/fetchDataArps";
import formatMoney from "../../utils/formatMoney";
import spin from "../../assets/spin.svg";

import {
  AiOutlineCheck,
  AiOutlinePrinter,
  AiOutlineSync,
} from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { BiLoader } from "react-icons/bi";
import postDataArps from "../../services/postDataArps";
import { Link } from "react-router-dom";
import TransferDocumentPrice from "./components/TransferDocumentPrice";
import ReactToPrint from "react-to-print";


const companies = [
  {
    key: "20",
    label: "Karimjee Value Chain Ltd.",
    description: "Karimjee Value Chain Ltd.",
    address1: "P.O Box 409,",
    address2: "Sokoine Drive,",
    address3: "Tel : (255) 22 2866815-9,",
    address4: "2866352-8.",
    address5: "Fax : (255) 22 2866815-9",
    tin: "TIN : 106-207-674",
    vrn: "VRN : 40-023170-U",
  },
  {
    key: "23",
    label: "Toyota Tanzania Ltd",
    description: "Toyota Geita Gold Mine",
    address1: "Plot no P.o Box 9060",
    address2: "Geita, Tanzania",
    address3: "Tel : 051 866815-9, 866352",
    address4: "Fax : 051 866814",
    address5: "",
    tin: "TIN : 100-146-304",
    vrn: "VRN : 10-0066645-E",
  },

  {
    key: "01",
    label: "Toyota Tanzania Ltd",
    description: "Toyota Dar-es-salaam",
    address1: "Plot no P.o Box 9060",
    address2: "Dar-es-salaam, Tanzania",
    address3: "Tel : 051 866815-9, 866352",
    address4: "Fax : 051 866814",
    address5: "",
    tin: "TIN : 100-146-304",
    vrn: "VRN : 10-0066645-E",
  },
];

export default function Statement() {
  const [entries, setEntries] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSyncing, setSyncing] = useState<boolean>(false);
  const [exchangeRate, setExchangeRate] = useState("1");
  const [currency, setCurrency] = useState("TZS");
  const [isNotTZS, setNotTZS] = useState<boolean>(false);
  const [canPrint, setPrint] = useState(false);
  const [account, setAccount] = useState("");

  const [tin, setTin] = useState("");
  const [vrn, setVrn] = useState("");
  const [dln, setDln] = useState("");

  const [name, setName] = useState("");
  const [box, setBox] = useState("");
  const [address, setAddress] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [swiftCode, setSwiftCode] = useState("");

  const rates: any = { TZS: 1, USD: 2330, EUR: 2364, GBP: 2810 };

  const [company, setCompany] = useState<any>(companies[0]);
  const docRef: any = useRef(null);
  const [isAllSelected, setAllSelected] = useState(false);

  const handleSyncData = async () => {
    setSyncing(true);
    const uri = `statements/upload`;
    const data = {};
    const response = await postDataArps(data, uri);
    if (response.success) {
      message.success("Data synched successfully");
    } else {
      message.error("Something went wrong");
    }
    setSyncing(false);
  };

  const handleFetchByaccount = async () => {
    setSelected([]);
    setLoading(true);
    const param = parseInt(account).toLocaleString('en-US', {
      minimumIntegerDigits: 5,
      useGrouping: false
    })
    const uri = `statements/${param}/company/${company.key}`;
    const response = await fetchDataArps(uri);

    if (response.success === false) {
      message.error(response.message);
      setLoading(false);
      return;
    }

    if (response.length === 0) {
      message.error(`No Records Found`);
      setEntries([]);
      setSelected([]);
      setLoading(false);
      return;
    }

    setEntries(response);
    setSelected(response)
    setLoading(false);
  };

  const handleClearState = async () => {
    setAccount("");
    setTin("");
    setVrn("");
    setDln("");
    setEntries([]);
    setSelected([]);
    setLoading(false);
    setSyncing(false);
    setAllSelected(false);
  };


  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const com = companies?.find((item: any) => item?.key === e.key);
    setCompany(com);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items = {companies}
    />
  );

  const handleCurrencyChange = (e: any) => {
    const currency = e;
    setExchangeRate(rates[currency]);
    if (currency === "TZS") {
      setNotTZS(false);
    } else {
      setNotTZS(true);
    }
    setCurrency(currency);
  };

  const handleAddItem = (item: any) => {
    setSelected([...selected, item]);
  };

  const handleRemoveItem = (item: any) => {
    var filtered = selected.filter(function (value: any) {
      return value._id !== item?._id;
    });
    setSelected(filtered);
  };

  const printDocument = () => {
    setPrint(true);
    const doc: jsPDF | any = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: "smart", 
    });

    if (docRef.current) {
      docRef.current.style.backgroundColor = "#FFFFFF";
      doc.html(docRef.current, {
        callback: function (doc: any) {
          doc.save(`Invoice.pdf`);
          setPrint(false);
        },
        x: 30,
        y: 30,
        margin: [0, 50, 0, 0],
        width: 595.28,
      });
    }
  };


  const exportDocumentToBuffer = () => {
    const doc: jsPDF = new jsPDF({})
    if (docRef.current) {
      docRef.current.style.backgroundColor = "#FFFFFF";

      // doc.html(docRef.current, {
      //   callback: function (doc: any) {
      //     doc.save(`Invoice.pdf`);
      //     setPrint(false);
      //   },
      //   x: 30,
      //   y: 30,
      //   margin: [0, 50, 0, 0],
      //   width: 595.28,
      // });
    }


  }

  const handleAddAll = () => {
    setSelected(entries);
    setAllSelected(true);
  };
  const handleRemoveAll = () => {
    setSelected([]);
    setAllSelected(false);
  };

  return (
    <>
      <div className="px-4 border-b  bg-gray-50">
        <div className="flex items-center">
          <div className="mr-1 text-toyota p-2 border-b border-toyota bg-gray-200 hover:text-toyota hover:cursor-pointer hover:bg-gray-200">
            <Link
              to={"/toyota/invoice"}
              className={"hover:text-toyota text-toyota"}
            >
              <div className="flex flex-col px-2 items-center">Account Statement</div>
            </Link>
          </div>
        </div>
      </div>
      <div
        style={{ fontSize: "14px" }}
        className="grid grid-cols-12 gap-0 mx-4 my-2 border rounded-lg"
      >
        <div className="bg-white col-span-2 rounded-l-lg border-r p-4">
          <input
            type="number"
            onChange={(e) => {
              setAccount(e.target.value);
            }}
            value={account}
            placeholder="Enter account number"
            className="border border-gray-400 p-2 rounded-md w-full justify-center items-start focus:border-toyota focus:outline-none"
          />

          <div className="w-full flex flex-1" style={{ width: "100%" }}>
            <Dropdown.Button overlay={menu} className="mt-2 w-full" style={{}}>
              <div className="flex justify-center items-center">
                <span className="ml-2">{company?.description}</span>
              </div>
            </Dropdown.Button>
          </div>

          <div className="flex border-t my-4">
            <button
              onClick={() => handleFetchByaccount()}
              className="p-2 bg-toyota hover:bg-blue-900 text-white my-2 mr-1 rounded-md w-full hover:text-white"
            >
              {isLoading ? (
                <div className="flex justify-center items-center pb-1">
                  <img src={spin} alt="spin" height="23px" width="23px" />
                </div>
              ) : (
                <div className=" flex justify-center items-center pb-1">
                  Submit
                </div>
              )}
            </button>
            <button
              onClick={() => handleClearState()}
              className="p-2 bg-white border border-gray-300 hover:border-toyota hover:text-toyota text-gray-800 my-2 ml-1 rounded-md w-full "
            >
              <div className="pb-1">Reset</div>
            </button>
          </div>

          <div className="flex flex-col">
            <span>Currency</span>
            <select
              className="border border-gray-400 p-1 rounded-md mb-2 mt-1 w-full"
              onChange={(e) => handleCurrencyChange(e.target.value)}
              value={currency}
            >
              <option selected hidden>
                {currency}
              </option>
              <option value="TZS">TZS</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
            </select>
            <div>
              {isNotTZS ? (
                <div>
                  <span>Exchange Rate</span>
                  <input
                    type="number"
                    onChange={(e) => {
                      setExchangeRate(e.target.value);
                    }}
                    value={exchangeRate}
                    placeholder="Exchange rate"
                    className="border border-gray-400 p-2 rounded-md w-full mb-4 justify-center items-start focus:border-toyota focus:outline-none"
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div className="mb-4">
            <span className="text-2xl">Customer Details</span>
            <input
              type="text"
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
              placeholder="Enter customer name"
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />

            <input
              type="text"
              onChange={(e) => {
                setBox(e.target.value);
              }}
              value={box}
              placeholder="P.O Box"
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />


            <input
              type="text"
              onChange={(e) => {
                setTin(e.target.value);
              }}
              value={tin}
              placeholder="Enter TIN No."
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />

            <input
              type="text"
              onChange={(e) => {
                setVrn(e.target.value);
              }}
              value={vrn}
              placeholder="Enter VRN No."
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />
          </div>

          <div className="mb-4">
            <span className="text-2xl">Bank Details</span>
            <input
              type="text"
              onChange={(e) => {
                setBankName(e.target.value);
              }}
              value={bankName}
              placeholder="Bank name"
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />

            <input
              type="text"
              onChange={(e) => {
                setAccountName(e.target.value);
              }}
              value={accountName}
              placeholder="Bank Account name"
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />


            <input
              type="text"
              onChange={(e) => {
                setAccountNumber(e.target.value);
              }}
              value={accountNumber}
              placeholder="Bank account Number"
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />

            <input
              type="text"
              onChange={(e) => {
                setSwiftCode(e.target.value);
              }}
              value={swiftCode}
              placeholder="Swift Code"
              className="border border-gray-400 p-2 rounded-md w-full mt-2 justify-center items-start focus:border-toyota focus:outline-none"
            />
          </div>

          <div className="">
            {entries.length === 0 ? null : (
              <button
                onClick={() => {
                  isAllSelected ? handleRemoveAll() : handleAddAll();
                }}
                className={` flex items-center px-2 py-2  mb-1 w-full border border-gray-300 p-2  hover:border-green-500  ${isAllSelected ? "bg-green-100 " : "hover:bg-gray-100 "
                  }`}
              >
                {isAllSelected ? (
                  <button
                    className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                    onClick={() => handleRemoveAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                ) : (
                  <button
                    className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-gray-500"
                    onClick={() => handleAddAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                )}
                <div className="ml-2">Select All</div>
              </button>
            )}
            {entries?.map((entry: any, index: number) => (
              <>
                <button
                  className={`border border-gray-300 p-2 mb-2 w-full hover:border-green-500  ${selected?.includes(entry)
                      ? "bg-green-100 "
                      : "hover:bg-gray-100 "
                    }`}
                  onClick={() =>
                    selected?.includes(entry)
                      ? handleRemoveItem(entry)
                      : handleAddItem(entry)
                  }
                  key={index}
                >
                  <div className="flex">
                    <div className="">
                      {selected?.includes(entry) ? (
                        <button
                          className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                          onClick={() => handleRemoveItem(entry)}
                        >
                          <AiOutlineCheck color="#FFFFFF" />
                        </button>
                      ) : (
                        <button
                          className="flex justify-center items-center h-6 w-8 border border-gray-600 hover:bg-gray-500"
                          onClick={() => handleAddItem(entry)}
                        >
                          <AiOutlineCheck color="#FFFFFF" />
                        </button>
                      )}
                    </div>
                    <div className="ml-2">
                      <span className="font-bold mr-1">Reference</span>
                      {entry?.reference}
                    </div>
                  </div>
                  <div className="my-2 text-left">{entry?.description}</div>
                  <div className="border-t-2 border-gray-500 mt-2 font-bold flex justify-end">
                    {currency}{" "}
                    {formatMoney(
                      entry?.total_amount
                        ? Number(entry?.total_amount / Number(exchangeRate))
                        : 0
                    )}
                  </div>
                </button>
              </>
            ))}
          </div>
        </div>
        <div className="bg-gray-50 col-span-10 h-full rounded-r-lg">
          <div className="flex">
            <div className="w-full">
              <div className="">
                <div className="py-3 px-8  border-b flex justify-between items-center bg-white rounded-t-lg">
                  <div className="">Customer Statement</div>
                  <div className="flex">
                    <button
                      onClick={() => handleSyncData()}
                      className="flex justify-center items-center border p-2 mr-2 text-sm"
                    >
                      <div className="flex justify-center items-center hover:text-toyota">
                        <div className="mr-1">
                          {isSyncing ? <BiLoader /> : <AiOutlineSync />}
                        </div>
                        <div>Sync Data</div>
                      </div>
                    </button>
                    {selected.length === 0 ? (
                      <button className="flex cursor-not-allowed justify-center border p-2 items-center focus:outline-none  mr-2 text-sm">
                        <AiOutlinePrinter className="mr-1" /> Print Invoice
                      </button>
                    ) : (
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <button className="flex justify-center border p-2 items-center focus:outline-none  mr-2 text-sm hover:text-toyota">
                              <AiOutlinePrinter className="mr-1" /> Print
                              Invoice
                            </button>
                          );
                        }}
                        content={() => docRef.current}
                        documentTitle={`Account Statement ${account}`}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                {selected.length === 0 ? (
                  <div className="h-screen">
                    <div className="flex flex-col justify-center items-center mt-32">
                      <div className="">
                        <BsSearch color="#9ca3af" />
                      </div>
                      <div className="text-gray-400">Nothing to preview</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="rounded-r-lg">


                      <div className="" ref={docRef}>
                        <TransferDocumentPrice
                          selected={selected}
                          company={company}
                          fields={{ 
                            tin: tin, 
                            vrn: vrn, 
                            dln: dln, 
                            name, 
                            box, 
                            address, 
                            accountName, 
                            accountNumber, 
                            swiftCode, 
                            bankName 
                          }}
                          currency={currency}
                          exchangeRate={exchangeRate}
                        />
                      </div>
                    </div>
                    <div className={`${canPrint ? "block" : "hidden"}`}>
                      <div className="bg-white w-full">
                        {/* <TransferDocumentPrint
                          id="print"
                          docRef={docRef}
                          selected={selected}
                          fields={{ tin: tin, vrn: vrn, dln: dln }}
                          company={company}
                          currency={currency}
                          exchangeRate={exchangeRate}
                        /> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
