import InvoiceItems from "./InvoiceItems";
import InvoiceTotals from "./InvoiceTotals";
import InvoiceLogo from "./InvoiceLogo";
import InvoiceCust from "./InvoiceCust";
import InvoiceFoot from "./InvoiceFoot";
import "./print.css"
export default function PrintInvoiceWrapper({
  docRef,
  selected,
  currency,
  exchangeRate,
  name,
  address1,
  address2,
  tin,
  vrn,
  isExempted
}: any) {
  if (selected.length === 0) {
    return <div className="h-screen" id="invoice-doc"></div>;
  }

  const { invoice = "-", service_date = new Date() } = selected[0];

  return (
    <div
      className="book bg-white"
      ref={docRef}
      style={{ fontSize: "10px", fontStretch: "inherit" }}
    >
      <div className="page bg-white">
        <div className="subpage bg-white">
          <div className="bg-white">
            <InvoiceLogo invoice_no={invoice} service_date={service_date} />
            <InvoiceCust
              item={selected[0]}
              name={name}
              address1={address1}
              address2={address2}
              tin={tin}
              vrn={vrn}
            />

            <div>
              <InvoiceItems
                selected={selected}
                currency={currency}
                exchangeRate={exchangeRate}
              />
              <InvoiceTotals
                selected={selected}
                taxcode={selected[0]?.sales_type}
                currency={currency}
                exchangeRate={exchangeRate}
                isExempted={isExempted}
              />
            </div>

            <InvoiceFoot
              selected={selected}
              taxcode={selected[0]?.sales_type}
              isExempted={isExempted}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
