import splitIntoChunks from "../../../../utils/splitIntoChunks";
import "./print.css";
import InvoiceItemsPrice from "./InvoiceItemsPrice";
import InvoiceTotalsPrice from "./InvoiceTotalsPrice";
import TransferCust from "./TransferCust";
import TransferHead from "./TransferHead";
import TransferNumber from "./TransferNumber";
import TransferTitle from "./TransferTitle";
import TransferVehicle from "./TransferVehicle";

export default function TransferDocumentPrint({
  selected,
  company,
  docRef,
}: any) {
  const chunks = splitIntoChunks(selected, 25);

  const docNo = selected[0]?.document_no;

  return (
    <div className="book bg-white" ref={docRef} style={{ fontSize: 10 }}>
      {chunks.map((page: any, index: number) => (
        <div className="page bg-white" key={index}>
          <div className="subpage bg-white">
            <div className="bg-white ">
              <div id="transfer-doc" style={{ fontSize: "11px" }}>
                <div className="">
                  <TransferHead company={company} />
                  <div
                    className="text-center -mt-8 mb-1"
                    style={{ fontSize: 16 }}
                  >
                    SPECIAL RELIEF-INV
                  </div>
                  <TransferTitle />
                  <TransferCust selected={selected} />
                  <TransferVehicle
                    selected={selected[0]}
                    pageno={index + 1}
                    pages={chunks.length}
                  />
                  <TransferNumber number={docNo} />
                  <div style={{ height: "100%" }}>
                    <InvoiceItemsPrice selected={selected} />
                    <InvoiceTotalsPrice
                      selected={selected}
                      totalItems={selected}
                      pageno={index + 1}
                      pages={chunks.length}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
