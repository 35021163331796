export default function TransferTitle() {
  return (
    <div className="flex mb-2">
      <div className="w-1/3"></div>

      <div className="w-2/3">
        <div className="text-lg ml-16">PARTS-INV</div>

        <div
          style={{ fontSize: "15px" }}
          className=" border border-black py-1 font-bold px-8 flex items-center"
        >
          <div className="text-center">ACCOUNT-TAX INVOICE</div>
        </div>
      </div>
    </div>
  );
}
