import logo from "../../../../assets/images/logo.png";
import qrcode from "../../../../assets/images/qrcode.png";

export default function TransferHead({ company }: any) {
  return (
    <div
      className="flex justify-between items-center mb-6"
      style={{ fontSize: 10 }}
    >
      <div className="w-2/6">
        <img className="mx-auto h-16 w-24" src={qrcode} alt="" />
        <div className="flex flex-col items-center">
          <div className="font-bold">8001 1277</div>
          <div className="uppercase" style={{ letterSpacing: 0.7 }}>
            Toyota Tanzania Ltd
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-2/6 mr-8">
        <span
          className="-mt-1"
          style={{
            fontSize: 15,
            fontWeight: "bold",
            letterSpacing: 0.7,
            marginBottom: 10,
          }}
        >
          Toyota Tanzania Ltd
        </span>
        <img className="mx-auto h-14" src={logo} alt="" />
      </div>
      <div
        className="flex flex-col justify-end font-bold w-2/6"
        style={{ fontWeight: "bold" }}
      >
        <span style={{ fontSize: 16 }}>{company?.label}</span>
        <span>{company?.description}</span>
        <span>{company?.address1}</span>
        {/* <span>{company?.address2}</span> */}
        <span>{company?.address3}</span>
        <span>{company?.address4}</span>
        <span style={{ marginTop: 2 }}>TIN: 100-146-304</span>
        <span>VRN: 10-0066645-E</span>
      </div>
    </div>
  );
}
