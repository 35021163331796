import { message } from "antd";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  user: any;
  token: any;
  signin: (user: string) => void;
  signout: () => void;
  setUser: () => any;
  setToken: () => any;
}

const AuthContext = createContext<any>(null!);

function getInitialUser() {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
}

function getInitialToken() {
  const token = localStorage.getItem("token");
  return token ? token : null;
}

const AuthProvider = ({ children, location }: any) => {
  const [user, setUser] = useState<any>(getInitialUser);
  const [token, setToken] = useState<any>(getInitialToken);

  const [isAuth, setAuth] = useState<boolean>(false);

  const navigate = useNavigate();

  const signin = (payload: any) => {
    const { user, token } = payload;
    if (token) {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      setToken(token);
      setUser(user);
    }
    navigate("/logistics/invoice");
  };

  const signout = () => {
    localStorage.setItem("user", "");
    localStorage.setItem("token", "");
    setUser(null);
    setToken(null);
    navigate("/");
    message.success("Logged out successfully");
  };

  const handleFetchAuth = async () => {
    if (token) {
      setToken(token);
    }
  };

  let value = { user, setUser, token, setToken, isAuth, signin, signout };

  useEffect(() => {
    handleFetchAuth();
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
