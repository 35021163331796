import formatMoney from "../../../utils/formatMoney";

export default function InvoiceItem({ item, index, exchangeRate }: any) {
  const { invoice_value, service_details } = item;
  return (
    <div className="grid grid-cols-12 items-center" key={index}>
      <div className="col-span-1 px-1 border-r  border-gray-400  text-center">
        {index + 1}
      </div>
      <div className="col-span-5 px-1  border-r border-gray-400  text-left tracking-wider">
        {service_details}
      </div>
      <div className="col-span-3 px-1 border-r border-gray-400 text-right">
        {formatMoney(invoice_value / Number(exchangeRate))}
      </div>
      <div className="col-span-3 px-1 border-gray-400 text-right">
        {formatMoney(Number(invoice_value / Number(exchangeRate)))}
      </div>
    </div>
  );
}
