const TRACE_BASE_URL = "https://api.kjl.co.tz:4000";
const ARPS_BASE_URL = "https://api.kjl.co.tz:3000";
const EMAIL_BASE_URL = "https://api.kjl.co.tz:8080";
const DMS_BASE_URL = "http://dms.toyotatz.com/dms/uploaded/tmp/jobcards/";

// const TRACE_BASE_URL = "http://192.168.0.101:8000";
// const ARPS_BASE_URL = "http://localhost:3000";
// const EMAIL_BASE_URL = "http://172.20.10.2:8080";

export { TRACE_BASE_URL, ARPS_BASE_URL, DMS_BASE_URL, EMAIL_BASE_URL };
