import LinearLink from "./LinearLink";

export default function VMIHead() {
  const links = [
    { name: "VMI Invoice", to: "/toyota/invoice/vmi" },
    { name: "Mbeya Invoice", to: "/toyota/invoice/mbeya" },
  ];

  return (
    <div className="px-4 border-b  bg-gray-50">
      <div className="flex items-center">
        {links.map((link: any, index: number) => (
          <LinearLink title={link?.name} to={link?.to} key={index} />
        ))}
      </div>
    </div>
  );
}
