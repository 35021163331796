import formatMoney from "../../../utils/formatMoney";

const ShowTotal = ({ fields, total = 0, exchangeRate }: any) => {
  const { bankName, accountName, accountNumber, swiftCode } = fields
  const formatted = `${formatMoney(total ? total / Number(exchangeRate) : 0)}`

  return (
    <>
      <div className="col-span-2 px-1 border-l border-b border-r py-1 border-black text-right text-black font-bold">
        {formatted}
      </div>
      <div className="col-span-12 bg-red border-0">
        <span className="block text-lg font-bold my-2"> Bank Details</span>
        <div className="grid grid-cols-12">
          <div className="bg-gray-300 col-span-4 font-bold px-2 py-2 border">Bank Name</div>
          <div className="px-2 py-2 text-left col-span-4 border">{bankName}</div>
      </div>
        <div className="grid grid-cols-12">
          <div className="bg-gray-300 col-span-4 font-bold px-2 py-2 border ">Account Number</div>
          <div className="px-2 py-2 text-left col-span-4 border">{accountNumber}</div>
      </div>
        <div className="grid grid-cols-12">
          <div className="bg-gray-300 col-span-4 font-bold px-2 py-2 border ">Account Name</div>
          <div className="px-2 py-2 text-left col-span-4 border">{accountName}</div>
      </div>
        <div className="grid grid-cols-12">
          <div className="bg-gray-300 col-span-4 font-bold px-2 py-2 border ">SwiftCode</div>
          <div className="px-2 py-2 text-left col-span-4 border">{swiftCode}</div>
        </div>
      </div>
    </>

  )
}


export default function InvoiceTotalsPrice({
  pageno,
  pages,
  totalItems,
  exchangeRate,
  fields
}: any) {
  const getTotal = () => {
    let total = 0;
    if (pageno !== pages) {
      return
      // const items = totalItems.slice(pageno - 1, pageno + 27)
      // total = items.reduce((accumulator: number, item: any) => accumulator + item?.outstanding, total)
      // return total;
    }
    totalItems.forEach((item: any) => {
      total = total + item?.outstanding;
    });
    return total;
  };

  const total = getTotal();

  return (
    <div className="">
      <div className="">
        <div className="grid grid-cols-12  border-l border-r-black border-l-white ">
          <div className="col-span-7 px-1 font-bold text-right"></div>
          <div
            className={`col-span-3 px-1 font-bold uppercase py-1 border-black  text-left ${pageno === pages ? "text-black" : "text-white"
              }`}
          >
            Total Amount Due
          </div>
          {
            pageno === pages
            ? <ShowTotal fields={fields} total={total} exchangeRate={exchangeRate} />
            : <div className="col-span-2 px-1 border-l border-r border-b py-1 border-black text-right text-black font-bold"></div>
          }
        </div>
      </div>
    </div >
  );
}

