import { BsClockHistory } from "react-icons/bs";
import LogisticsTool from "../../components/shared/LogisticsTool";

export default function Soon() {
  return (
    <div className="h-screen">
      <LogisticsTool />

      <div className="flex flex-col justify-center items-center mt-24">
        <BsClockHistory color="black" size={24} />
        Coming Soon
      </div>
    </div>
  );
}
