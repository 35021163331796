import moment from "moment";

export default function TransferCust({ selected }: any) {
  return (
    <div className="flex mt-1">
      <div className="w-2/5 ">
        <div style={{ fontSize: 13 }} className="py-1 font-bold">
          Receipt of deposit/payment from:
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col flex-1 mb-6">
            <span style={{ fontSize: 15 }} className="mb-4 font-bold">
              {selected[0]?.customer}
            </span>
            <span>{selected[0]?.address1}</span>
            <span>{selected[0]?.address2}</span>
            <span>{selected[0]?.address3}</span>
            <span>{selected[0]?.address4}</span>
          </div>
        </div>
      </div>
      <div className="w-1/5"></div>
      <div className="w-2/5" style={{ fontSize: 12 }}>
        <div className="px-2 pb-1 text-lg border border-black text-center font-bold">
          RECEIPT
        </div>
        <div className="flex justify-between border-b border-r border-l border-black">
          <div className="w-24 border-r border-black px-2 py-1">Date:</div>
          <div className="px-2 py-1">
            {moment(selected[0]?.date.toString()).format("DD/MM/YYYY")}
          </div>
        </div>
        <div className="flex justify-between border-b border-r border-l border-black">
          <div className="w-24 border-r border-black px-2 py-1">Time:</div>
          <div className="px-2 py-1">
            {moment(selected[0]?.date.toString()).format("HH:MM A")}
          </div>
        </div>
        <div className="flex justify-between border-b border-r border-l border-black">
          <div className="w-24 border-r border-black px-2 py-1">Account:</div>
          <div className="px-2 py-1">{selected[0]?.account}</div>
        </div>
      </div>
    </div>
  );
}
