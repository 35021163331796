import formatMoney from "../../../utils/formatMoney";

export default function InvoiceItemsPrice({ currency }: any) {
  return (
    <div className="">
      <div style={{ height: "100%" }} className="mt-2  border border-black">
        <div className="grid grid-cols-12 border-b  border-black  bg-gray-300">
          <div className="col-span-3  px-1 py-1 font-bold  text-black  text-center">
            Date
          </div>
          <div className="col-span-3 px-1  py-1 font-bold   text-black text-center">
            Document No
          </div>
          <div className="col-span-3 px-1  py-1 font-bold  text-black text-center">
            Reference No
          </div>

          <div className="col-span-3 px-2  py-1 font-bold  text-black text-right">
            <span className="mr-2">Amount</span> ({currency})
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-3  px-1 py-1 font-bold  text-black  text-center">
            09/09/2022
          </div>
          <div className="col-span-3 px-1  py-1 font-bold   text-black text-center">
            0
          </div>
          <div className="col-span-3 px-1  py-1 font-bold  text-black text-center">
            00020347
          </div>

          <div className="col-span-3 px-2  py-1 font-bold  text-black text-right">
            {formatMoney(1200000)}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 border  border-black  bg-gray-300 mt-1">
        <div className="col-span-3  px-1 py-1 font-bold  text-black  text-center"></div>
        <div className="col-span-4 px-1  py-1 font-bold   text-black text-center"></div>
        <div className="col-span-3 px-1  py-1 font-bold  text-black text-center">
          Suffix
        </div>

        <div className="col-span-2 px-1  py-1 font-bold  text-black text-center">
          User
        </div>
      </div>
      <div className="grid grid-cols-12 border-r border-l border-b border-black">
        <div className="col-span-3  px-1 py-1 font-bold  text-black  text-center"></div>
        <div className="col-span-4 px-1  py-1 font-bold   text-black text-center"></div>
        <div className="col-span-3 px-1  py-1 font-bold  text-black text-center"></div>

        <div className="col-span-2 px-2  py-1 font-bold  text-black text-center">
          akihwele
        </div>
      </div>
    </div>
  );
}
