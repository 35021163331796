import moment from "moment";

export default function TransferCust({ selected, fields, page }: any) {
  const { address1, address2, address3, address4, address5 } = selected
  const { tin, vrn } = fields
  const name = fields?.name || selected?.customer_name
  const box = fields?.box || address1

  const getTin = () => {
    const lowerAddress3 = address3 && address3.toLowerCase() || ""
    const lowerAddress4 = address4 && address4.toLowerCase() || ""
    const lowerAddress5 = address5 && address5.toLowerCase() || ""
    if (tin) {
      return `Tin: ${tin}`
    }else if (lowerAddress3.includes("tin")) {
      return address3
    } else if (lowerAddress4.includes('tin')) {
      return address4
    } else if (lowerAddress5.includes('tin')) {
      return address5
    }  else {
      return ""
    }
  }

  const getVRN = () => {
    const lowerAddress4 = address4 && address4.toLowerCase() || ""
    const lowerAddress5 = address5 && address5.toLowerCase() || ""
    if (vrn) {
      return `Vrn: ${vrn}`
    }else if (lowerAddress4.includes('vrn')) {
      return address4
    } else if (lowerAddress5.includes('vrn')) {
      return address5
    } else {
      return ""
    }
  }


  return (
    <div className="flex justify-between mt-1 gap-4">
      <div className="flex flex-col p-4 items-start border-black mb-2 justify-start w-2/4 border">
        <span style={{ fontSize: 15 }}>{fields?.label}</span>
        <span>{name}</span>
        <span>{box}</span>
        <span>{fields?.address || address2}</span>
        <span>{fields?.address3}</span>
        <span>{fields?.address4}</span>
        <span style={{ marginTop: 2 }}>{getTin()}</span>
        <span>{getVRN()}</span>
      </div>


      <div className="flex flex-col items-end mb-2 justify-end w-2/4 ">
        <span className="text-black text-2xl mb-2 font-bold">Statement</span>
        <div className="border-t border-l border-r  border-b border-black font-bold flex flex-row">
          <div>
            <div className="bg-gray-300 text-center border-b border-r border-black px-2">
              Date
            </div>
            <div className="flex flex-col p-2 border-r text-center border-black">
              <span>
                {moment().format("DD/MM/YYYY")}
              </span>
            </div>
          </div>

          <div>
            <div className="bg-gray-300 border-b border-r border-black px-2">
              Account No.
            </div>
            <div className="flex flex-col p-2 text-center border-black border-r ">
              <span>{selected[0]?.account}</span>
            </div>
          </div>

          <div>
            <div className="bg-gray-300 border-b  border-black px-2">
              Page
            </div>
            <div className="flex flex-col p-2 text-center border-black ">
              <span>{page}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
