import splitIntoChunks from "../../../../utils/splitIntoChunks";
import PrintWrapperPrice from "./PrintWrapperPrice";
import "./print.css";

export default function PrintDocumentPrice({
  selected,
  company,
  tin,
  lpo,
  address1,
  address2,
  isFileCopy,
}: any) {
  const chunks = splitIntoChunks(selected, 28);
  return (
    <div className="book h-full">
      {chunks.map((page: any, index: number) => (
        <div className="page bg-white" key={index}>
          <div className="subpage bg-white">
            <PrintWrapperPrice
              selected={page}
              pageno={index + 1}
              pages={chunks.length}
              totalItems={selected}
              company={company}
              tin={tin}
              lpo={lpo}
              address1={address1}
              address2={address2}
              isFileCopy={isFileCopy}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
