import moment from "moment";
import { operators } from "../../../utils/data";

export default function TransferCust({ selected }: any) {

  return (
    <div className="flex mt-1">
      <div className="w-2/5 border-t border-l border-r border-black font-bold">
        <div className="bg-gray-300 border-b border-black px-2">
          Name & Address
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col px-2 flex-1 mb-10">
            <span>{selected[0]?.customer}</span>
            <span></span>
            <span></span>
          </div>
          <div className="flex items-center border-t border-b border-black h-8 px-2"></div>
        </div>
      </div>
      <div className="w-1/5">
        <div className="flex flex-col items-center">
          <span>Account No.</span>
          <span className="text-sm font-bold">{selected[0]?.account}</span>
        </div>
        <div className="flex flex-col items-center mt-6">
          <span>Date</span>
          <span className="text-sm font-bold">
            {moment(selected[0]?.decarded.toString()).format("DD/MM/YYYY")}
          </span>
          <span className="">Order Number</span>
        </div>
      </div>
      <div className="w-2/5 border-t border-l border-r border-black font-bold">
        <div className="bg-gray-300 border-b border-black px-2">
          Driver Name & Address
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col px-2 flex-1 mb-10">
            <span>{selected[0]?.customer}</span>
            <span></span>
            <span></span>
          </div>
          <div className="flex items-center border-t border-b border-black h-8 px-2">
            Operator : {operators[selected[0]?.operator]}
          </div>
        </div>
      </div>
    </div>
  );
}
