export default function TransferNumber({ number, fields }: any) {
  return (
    <div className="flex items-center">
      <div className="">
        Document Number : <span className="ml-2">{number}</span>,
      </div>
      <div className="ml-4">
        Delivery Note Ref: <span className="ml-2">{fields?.dln}</span>
      </div>
    </div>
  );
}
