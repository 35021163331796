export default function InvoiceTotalsMain({ pageno, pages, data }: any) {
  const getQuantity = () => {
    let total = 0;
    if (pageno !== pages) {
      return total;
    }

    data?.forEach((item: any) => {
      total = total + item?.quantity;
    });
    return total;
  };

  const total = getQuantity();
  return (
    <div className="">
      <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
        <div className="col-span-7 px-1 font-bold text-right"></div>
        <div
          className={`col-span-4 px-1 font-bold border-r border-l border-black  text-left  ${
            pageno === pages ? "text-black" : "text-white"
          }
          `}
        >
          {pageno === pages ? "Total" : "-"}
        </div>
        <div
          className={`col-span-1 px-1   border-black text-center font-bold text-white ${
            pageno === pages ? "text-black" : "text-white"
          }`}
        >
          {pageno === pages ? total : "-"}
        </div>
      </div>
      <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
        <div className="col-span-7 px-1 font-bold text-right "></div>
        <div
          className={`col-span-4 px-1 font-bold border-r border-l border-black  text-left text-white
          }`}
        >
          -
        </div>
        <div className="col-span-1 px-1   border-black text-right font-bold text-white">
          -
        </div>
      </div>{" "}
      <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
        <div className="col-span-7 px-1 font-bold text-right "></div>
        <div
          className={`col-span-4 px-1 font-bold border-r border-l border-black  text-left text-white
          }`}
        ></div>
        <div className="col-span-1 px-1   border-black text-right font-bold text-white">
          -
        </div>
      </div>{" "}
      <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
        <div className="col-span-7 px-1 font-bold text-right "></div>
        <div
          className={`col-span-4 px-1 font-bold border-r border-l border-black  text-left text-white
          }`}
        >
          -
        </div>
        <div className="col-span-1 px-1   border-black text-right font-bold text-white">
          -
        </div>
      </div>{" "}
      <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
        <div className="col-span-7 px-1 font-bold text-right"></div>
        <div
          className={`col-span-4 px-1 font-bold border-r border-l border-b border-black  text-left text-white
          }`}
        >
          -
        </div>
        <div className="col-span-1 px-1 border-b  border-black text-right font-bold text-white">
          -
        </div>
      </div>
    </div>
  );
}
