import logistics from "../../../assets/images/kvcl_white.png";

export default function TransferHead() {
  return (
    <div className="flex justify-between items-center mb-6">
      <div className="">
        <img className="h-24 -ml-1" src={logistics} alt="" />
      </div>
      <div className="-ml-8"></div>
      <div style={{ fontSize: "12px" }} className="flex flex-col font-bold">
        <span style={{ fontSize: 14 }}> KARIMJEE VALUE CHAIN LTD</span>
        <span>P.O BOX 409</span>
        <span>SOKOINE DRIVE</span>
        <span>DAR ES SALAAM</span>
        <span>TIN: 106-207-674</span>
        <span>VRN: 40-023170-U</span>
      </div>
    </div>
  );
}
