import TransferVehicle from "./TransferVehicle";
import TransferHead from "./TransferHead";
import TransferCust from "./TransferCust";
import InvoiceItemsPrice from "./InvoiceItemsPrice";
import InvoiceTotalsPrice from "./InvoiceTotalsPrice";

export default function TransferWrapperPrice({
  selected,
  pageno,
  pages,
  totalItems,
  company,
  currency,
  exchangeRate,
  fields,
}: any) {
  return (
    <div id="transfer-doc" style={{ fontSize: "11px" }}>
      <div className="">
        <TransferHead company={company} />
        <TransferCust selected={selected[0]} fields={fields} page={pageno}  />
        <TransferVehicle selected={selected[0]} pageno={pageno} pages={pages} />
        <div style={{ height: "100%" }}>
          <InvoiceItemsPrice
            selected={selected}
            currency={currency}
            exchangeRate={exchangeRate}
          />
          <InvoiceTotalsPrice
            selected={selected}
            totalItems={totalItems}
            pageno={pageno}
            pages={pages}
            currency={currency}
            exchangeRate={exchangeRate}
            fields={fields}
          />
        </div>
      </div>
    </div>
  );
}
