import formatMoney from "../../../../utils/formatMoney";

export default function InvoiceTotalsPrice({ pageno, pages, totalItems }: any) {
  const getTotal = () => {
    let total = 0;
    if (pageno !== pages) {
      return total;
    }

    totalItems.forEach((item: any) => {
      total = total + item?.net_sale;
    });
    return total;
  };

  const total = getTotal();

  return (
    <div className="">
      <div className="">
        <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
          <div className="col-span-8 pl-4">
            <div className="flex justify-between">
              <div className="">Dear Customer,</div>
              <div className="flex px-2 w-48 justify-between border-l border-b border-black">
                <div className="">W/Holding Tax</div>
                <div className="">{formatMoney(total ? total * 0.05 : 0)}</div>
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 px-1 font-bold border-r border-l border-black  text-left ${
              pageno === pages ? "text-black" : "text-white"
            }`}
          >
            Nett
          </div>
          <div className="col-span-2 px-1   border-black text-right text-black font-bold">
            {pageno === pages ? `${formatMoney(total ? total : 0)}` : ""}
          </div>
        </div>
        <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
          <div className="col-span-8 pl-4">
            <div className="flex justify-between">
              <div className="">For your next service or repair,</div>
              <div className="flex px-2 w-48 justify-between ">
                <div className="">Total Parts</div>
                <div className="">{formatMoney(0)}</div>
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 px-1 font-bold border-r border-l border-black  text-left ${
              pageno === pages ? "text-black" : "text-white"
            }`}
          >
            V.A.T
          </div>
          <div className="col-span-2 px-1   border-black text-right text-black font-bold">
            {pageno === pages ? formatMoney(0) : ""}
          </div>
        </div>
        <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
          <div className="col-span-8 pl-4">
            <div className="flex justify-between">
              <div className="">please contact us for an</div>
              <div className="flex px-2 w-48 justify-between ">
                <div className="">Total Labour</div>
                <div className="">{formatMoney(total ? total : 0)}</div>
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 px-1 font-bold border-r border-l border-black  text-left ${
              pageno === pages ? "text-black" : "text-white"
            }`}
          >
            Total
          </div>
          <div className="col-span-2 px-1   border-black text-right text-black font-bold">
            {pageno === pages ? `${formatMoney(total ? total : 0)}` : ""}
          </div>
        </div>
        <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
          <div className="col-span-8 pl-4">
            <div className="flex justify-between">
              <div className="">appointment. Your cooperation is</div>
              {/* <div className="flex px-2 w-48 justify-between ">
                <div className="">Total Labour</div>
                <div className="">994,500.00</div>
              </div> */}
            </div>
          </div>
          <div
            className={`col-span-2 px-1 font-bold border-r border-l border-black  text-left ${
              pageno === pages ? "text-black" : "text-white"
            }`}
          >
            Paid
          </div>
          <div className="col-span-2 px-1   border-black text-right text-black font-bold">
            {pageno === pages ? `${formatMoney(0)}` : ""}
          </div>
        </div>{" "}
        <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
          <div className="col-span-8 pl-4">
            <div className="flex justify-between">
              <div className="">highly appreciated.</div>
              <div className="flex px-2 w-48 justify-between ">
                <div className="">TSH</div>
                <div className=""></div>
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 px-1 font-bold border-r border-l border-b border-black  text-left ${
              pageno === pages ? "text-black" : "text-white"
            }`}
          >
            Owing
          </div>
          <div className="col-span-2 px-1 border-b  border-black text-right text-black font-bold">
            {pageno === pages ? `${formatMoney(total ? total : 0)}` : ""}
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className=""></div>
        <div className="" style={{ fontSize: 8 }}>
          <div className="">Analysis For V.A.T purposes:</div>
          <div className="flex justify-between">
            <div className="">Gross</div>
            <div className="">{formatMoney(total ? total : 0)}</div>
          </div>
          <div className="flex justify-between">
            <div className="">VAT</div>
            <div className="">{formatMoney(0)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
