import formatMoney from "../../../utils/formatMoney";

export default function InvoiceTotals({
  selected,
  taxcode,
  exchangeRate,
  isExempted
}: any) {
  const calculateGrandTotal = () => {
    let total = 0;
    selected.map((item: any) => {
      total = total + item?.invoice_value;
    });
    return total;
  };

  const totalBeforeTax = calculateGrandTotal();
  const calculateTax = () => {
    let tax = 0
    if(!isExempted){
      tax = taxcode === "N" ? totalBeforeTax * 0 : totalBeforeTax * 0.18;
    }
    return tax
  }


  const totalTax = calculateTax();
  const totalAfterTax = totalBeforeTax + totalTax;

  return (
    <div className="">
      <div className="grid grid-cols-12 border-r border-r-gray-400 border-l border-l-white ">
        <div className="col-span-1 p-1 font-bold text-white  text-center"></div>
        <div className="col-span-1 p-1 font-bold text-white text-center"></div>
        <div className="col-span-4 p-1 font-bold border-r border-gray-400 text-white text-center"></div>
        <div className="col-span-3 p-1 font-bold border-b border-r border-gray-400  text-right px-2 ">
          SUBTOTAL
        </div>
        <div className="col-span-3 p-1  border-b  border-gray-400 text-right">
          {formatMoney(calculateGrandTotal() / Number(exchangeRate))}
        </div>
      </div>
      <div className="grid grid-cols-12 border-r border-r-gray-400 border-l border-l-white ">
        <div className="col-span-1 p-1 font-bold text-white  text-center"></div>
        <div className="col-span-1 p-1 font-bold text-white text-center"></div>
        <div className="col-span-4 p-1 font-bold border-r border-gray-400 text-white text-center"></div>
        <div className="col-span-3 p-1 font-bold border-r border-b border-gray-400  text-right px-2 ">
          TAX
        </div>
        <div className="col-span-3 p-1 border-b border-gray-400 text-gray-800 text-right">
          {formatMoney(totalTax / Number(exchangeRate))}
        </div>
      </div>
      <div className="grid grid-cols-12  mt-2">
        <div className="col-span-1 p-1 font-bold text-white  text-center"></div>
        <div className="col-span-1 p-1 font-bold text-white text-center"></div>
        <div className="col-span-4 p-1 font-bold border-r border-toyota text-white text-center"></div>
        <div className="col-span-3 p-1 font-bold border-b border-t border-r border-r-gray-400  border-toyota bg-toyota text-white text-right px-2 ">
          TOTAL AMOUNT DUE
        </div>
        <div className="col-span-3 p-1 border-r border-t border-toyota  font-bold text-white bg-toyota text-right">
          {formatMoney(totalAfterTax / Number(exchangeRate))}
        </div>
      </div>
    </div>
  );
}
