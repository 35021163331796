import splitIntoChunks from "../../../../utils/splitIntoChunks";
import "./custom.css";
import TransferWrapperMain from "./TransferWrapperMain";

export default function TransferDocumentMain({
  selected,
  isGatePass,
  company,
  fields
}: any) {
  const chunks = splitIntoChunks(selected, 32);
  return (
    <div className="book">
      {chunks.map((page: any, index: number) => (
        <div className="page-transfer" key={index}>
          <div className="subpage-transfer">
            <TransferWrapperMain
              selected={page}
              data={selected}
              fields={fields}
              pageno={index + 1}
              pages={chunks.length}
              totalItems={selected?.length}
              isGatePass={isGatePass}
              company={company}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
