import TransferHead from "./TransferHead";
import TransferCust from "./TransferCust";
import InvoiceItemsPrice from "./InvoiceItemsPrice";
import InvoiceTotalsPrice from "./InvoiceTotalsPrice";

export default function TransferWrapperPrice({
  exchangeRate,
  selected,
  pageno,
  pages,
  totalItems,
  currency,
}: any) {
  return (
    <div id="transfer-doc" style={{ fontSize: "11px" }}>
      <div className="">
        <TransferHead />
        <TransferCust selected={selected} />
        <div style={{ height: "100%" }}>
          <InvoiceItemsPrice
            selected={selected}
            exchangeRate={exchangeRate}
            currency={currency}
          />
          <InvoiceTotalsPrice
            exchangeRate={exchangeRate}
            selected={selected}
            totalItems={totalItems}
            currency={currency}
            pageno={pageno}
            pages={pages}
          />
        </div>
      </div>
    </div>
  );
}
