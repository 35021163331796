import logo from "../../../../assets/images/logo.png";
import qrcode from "../../../../assets/images/qrcode.png";

export default function TransferHead({ company }: any) {
  return (
    <div className="flex justify-between items-center mb-6">
      <div className="w-1/3">
        <img className="mx-auto h-24 w-32" src={qrcode} alt="" />
        <div className="flex flex-col items-center">
          <div className="font-bold">8001 1277</div>
          <div className="uppercase">Toyota Tanzania Ltd</div>
        </div>
      </div>
      <div className="w-1/3 -mt-4 mr-8 flex flex-col justify-center items-center">
        <div className="uppercase" style={{ fontSize: 15 }}>
          Toyota Tanzania Ltd
        </div>
        <img className="mx-auto h-20" src={logo} alt="" />
      </div>
      <div style={{ fontSize: 10 }} className="flex flex-col font-bold w-1/3">
        <span className="text-lg -mt-1">{company?.label}</span>
        <span>{company?.address1}</span>
        <span>{company?.address2}</span>
        <span>{company?.address3}</span>
        <span>{company?.address4}</span>
        <span className="mt-1">TIN: 100-146-304</span>
        <span>VRN: 10-0066645-E</span>
      </div>
    </div>
  );
}
