export default function TransferTitle() {
  return (
    <div className="flex mb-2">
      <div className="w-1/3"></div>

      <div className="w-2/3">
        <div className="ml-16" style={{ fontSize: 12 }}>
          PARTS-INV
        </div>

        <div
          style={{ fontSize: 12 }}
          className="flex items-center border border-black py-1 font-bold px-8 "
        >
          <div className="text-center">ACCOUNT-TAX INVOICE</div>
        </div>
      </div>
    </div>
  );
}
