import LinearLink from "./LinearLink";

export default function LogisticsTool() {
  const links = [
    { name: "Invoice", to: "/logistics/invoice" },
    { name: "Debit Note", to: "/logistics/debitnote" },
    { name: "Credit Note", to: "/logistics/creditnote" },
    { name: "Receipt", to: "/logistics/receipt" },
  ];

  return (
    <div className="px-4 border-b  bg-gray-50">
      <div className="flex items-center">
        {links.map((link: any, index: number) => (
          <LinearLink title={link?.name} to={link?.to} key={index} />
        ))}
      </div>
    </div>
  );
}
