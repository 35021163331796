import InvoiceItemsMain from "../../../transfers/components/InvoiceItemsMain";
import InvoiceTotalsMain from "../../../transfers/components/InvoiceTotalsMain";
import TransferCust from "../../../transfers/components/TransferCust";
import TransferNumber from "../../../transfers/components/TransferNumber";
import TransferTitle from "../../../transfers/components/TransferTitle";
import TransferVehicle from "../../../transfers/components/TransferVehicle";
import TransferHead from "./TransferHead";


export default function TransferWrapperMain({
  selected,
  pageno,
  pages,
  totalItems,
  isGatePass,
  data,
  company,
  fields
}: any) {
  const docNo = selected[0]?.document_no;
  return (
    <div id="transfer-doc" style={{ fontSize: "11px" }}>
      <div className="">
        <TransferHead company={company} />
        <TransferTitle />
        <TransferCust selected={selected} fields={fields} />
        <TransferVehicle selected={selected[0]} pageno={pageno} pages={pages} />
        <TransferNumber number={docNo} />
        <div style={{ height: "100%" }}>
          <InvoiceItemsMain selected={selected} isGatePass={isGatePass} />
          <InvoiceTotalsMain
            totalItems={totalItems}
            pageno={pageno}
            pages={pages}
            selected={selected}
            data={data}
          />
        </div>
      </div>
    </div>
  );
}
