import TransferVehicle from "./TransferVehicle";
import TransferHead from "./TransferHead";
import TransferTitle from "./TransferTitle";
import TransferCust from "./TransferCust";
import TransferNumber from "./TransferNumber";
import InvoiceItemsPrice from "./InvoiceItemsPrice";
import InvoiceTotalsPrice from "./InvoiceTotalsPrice";

export default function TransferWrapperPrice({
  selected,
  pageno,
  pages,
  totalItems,
  company,
  currency,
  exchangeRate,
  fields,
}: any) {
  const docNo = selected[0]?.document_no;
  return (
    <div id="transfer-doc" style={{ fontSize: 9 }}>
      <div className="">
        <TransferHead company={company} />
        <TransferTitle />
        <TransferCust selected={selected} fields={fields} />
        <TransferVehicle selected={selected[0]} pageno={pageno} pages={pages} />
        <TransferNumber number={docNo} fields={fields} />
        <div>
          <InvoiceItemsPrice
            selected={selected}
            currency={currency}
            exchangeRate={exchangeRate}
          />
          <InvoiceTotalsPrice
            selected={selected}
            totalItems={totalItems}
            pageno={pageno}
            pages={pages}
            currency={currency}
            exchangeRate={exchangeRate}
          />
        </div>
      </div>
    </div>
  );
}
