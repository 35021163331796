import TransferVehicle from "./TransferVehicle";
import TransferHead from "./TransferHead";
import TransferTitle from "./TransferTitle";
import TransferCust from "./TransferCust";
import TransferNumber from "./TransferNumber";
import InvoiceItemsPrice from "./InvoiceItemsPrice";
import InvoiceTotalsPrice from "./InvoiceTotalsPrice";

export default function PrintWrapperPrice({
  selected,
  pageno,
  pages,
  totalItems,
  company,
  tin,
  lpo,
  address1,
  address2,
  isFileCopy,
}: any) {
  const docNo = selected[0]?.document_no;
  return (
    <div style={{ height: "100%" }}>
      <div className="">
        <TransferHead company={company} />
        <TransferTitle />
        <TransferCust
          selected={selected}
          tin={tin}
          lpo={lpo}
          address1={address1}
          address2={address2}
        />
        <TransferVehicle selected={selected[0]} pageno={pageno} pages={pages} />
        <TransferNumber number={docNo} />
        <div style={{ height: "100%" }}>
          <InvoiceItemsPrice selected={selected} isFileCopy={isFileCopy} />
          <InvoiceTotalsPrice
            selected={selected}
            totalItems={totalItems}
            pageno={pageno}
            pages={pages}
          />
        </div>
      </div>
    </div>
  );
}
