import formatMoney from "../../../utils/formatMoney";

export default function InvoiceTotalsPrice({
  pageno,
  pages,
  totalItems,
  exchangeRate,
  selected,
  currency,
}: any) {
  const getTotal = () => {
    let total = 0;
    if (pageno !== pages) {
      return total;
    }

    totalItems.forEach((item: any) => {
      total = total + item?.net_sale;
    });
    return total;
  };

  const total = getTotal();

  return (
    <div className="">
      <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white ">
        <div className="col-span-1 border-r border-black font-bold text-right">
          <div className="flex justify-end items-center">
            <div className="border-l w-4 text-center bg-gray-100 border-b border-black">
              V
            </div>
          </div>
        </div>
        <div className="col-span-4 px-1 font-bold text-right bg-gray-100 border-b border-r border-black">
          Description
        </div>
        <div className="col-span-1 px-1 font-bold text-right bg-gray-100 border-b border-r border-black">
          Rate
        </div>
        <div className="col-span-2 px-1 font-bold text-right bg-gray-100 border-b border-r border-black">
          Goods Total
        </div>
        <div className="col-span-2 px-1 font-bold border-r   text-left bg-gray-100 border-b border-black">
          VAT Total
        </div>
        <div className="col-span-2 px-1    text-right text-black font-bold bg-gray-100 border-b border-black">
          Total
        </div>
      </div>
      <div className="grid grid-cols-12 border-r border-l border-r-black border-l-white h-12">
        <div className="col-span-1 border-r border-black font-bold text-right">
          <div className="flex justify-end items-center">
            <div className="border-l border-b border-black w-4 text-center  h-12">
              {selected[0]?.vat}
            </div>
          </div>
        </div>
        <div className="col-span-4 px-1 font-bold text-right border-b border-r border-black"></div>
        <div className="col-span-1 px-1 font-bold text-right border-b border-r border-black">
          {formatMoney(0)}
        </div>
        <div className="col-span-2 px-1 font-bold text-right border-b border-r border-black">
          {formatMoney(total / Number(exchangeRate))}
        </div>
        <div className="col-span-2 px-1 font-bold border-r border-b border-black  text-left"></div>
        <div className="col-span-2 px-1 border-b  border-black text-right text-black font-bold">
          {pageno === pages
            ? `${formatMoney(total ? total / Number(exchangeRate) : 0)}`
            : ""}
        </div>
      </div>
      <div className="grid grid-cols-12 mt-2 font-bold">
        <div style={{ fontSize: 15 }} className="col-span-6 text-right p-2 ">
          Grand Total ({currency})
        </div>
        <div className="col-span-2 text-right border-l-2 border-r-2 border-t-2 border-b-2 border-black p-2">
          {formatMoney(total / Number(exchangeRate))}
        </div>
        <div className="col-span-2 text-right border-t-2 border-r-2 border-b-2 border-black p-2">
          {formatMoney(0)}
        </div>
        <div className="col-span-2 text-right border-t-2 border-r-2  border-b-2 border-black p-2">
          {formatMoney(total / Number(exchangeRate))}
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-6 text-right px-2"></div>
        <div style={{ fontSize: 10 }} className="col-span-6 text-left">
          <div className="my-1">
            <div className="border-2 border-black">
              <div className="flex justify-between mb-1 font-bold p-1 border-b border-black">
                <div className="">Summary in TZS</div>
                <div className="">
                  Exchange Rate:{" "}
                  <span className="underline"> {exchangeRate} </span>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="px-1 font-bold">SUBTOTAL</div>
                <div className="px-1">{formatMoney(total)}</div>
              </div>
              <div className="flex justify-between">
                <div className="px-1 font-bold">VAT</div>
                <div className="px-1">{formatMoney(0)}</div>
              </div>
              <div className="flex justify-between">
                <div className="px-1 font-bold">TOTAL</div>
                <div className="px-1">{formatMoney(total)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12">
        <div
          style={{ fontSize: 12 }}
          className="col-span-6 text-right p-2 font-bold"
        >
          Authorised Signatory :
        </div>
        <div className="col-span-6 text-right border-l-2 border-r-2 border-t-2 border-b-2 border-black p-2"></div>
      </div>
    </div>
  );
}
