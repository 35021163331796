import formatMoney from "../../../utils/formatMoney";

export default function InvoiceFoot({ selected, taxcode, isExempted }: any) {
  const calculateGrandTotal = () => {
    let total = 0;
    selected.map((item: any) => {
      total = total + item?.invoice_value;
    });
    return total;
  };

  const totalBeforeTax = calculateGrandTotal();
  const calculateTax = () => {
    let tax = 0
    if(!isExempted){
      tax = taxcode === "N" ? totalBeforeTax * 0 : totalBeforeTax * 0.18;
    }
    return tax
  }


  const totalTax = calculateTax();
  const totalAfterTax = totalBeforeTax + totalTax;

  return (
    <div className="footer">
      <div className="flex justify-between items-center w-full mt-4">
        <div className="flex flex-col">
          <div className="flex">
            <div className="w-24 font-bold">Bank Details :</div>
            <div className="tracking-wider">USD ACCOUNT</div>
          </div>
          <div className="flex">
            <div className="w-24 font-bold">A/c Name :</div>
            <div className="tracking-wider">KARIMJEE VALUE CHAIN LTD</div>
          </div>
          <div className="flex">
            <div className="w-24 font-bold">A/c Number :</div>
            <div className="">0140015196</div>
          </div>
          <div className="flex">
            <div className="w-24 font-bold">Bank :</div>
            <div className="tracking-wider">EXIM BANK (T) LTD</div>
          </div>
          <div className="flex">
            <div className="w-24 font-bold">Branch Name :</div>
            <div className="tracking-wider">NYERERE ROAD</div>
          </div>
          <div className="flex">
            <div className="w-24 font-bold">Swift code :</div>
            <div className="">EXTNTZTZ</div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="flex">
            <div className="tracking-wider">TZS ACCOUNT</div>
          </div>
          <div className="flex">
            <div className="tracking-wider">KARIMJEE VALUE CHAIN LTD</div>
          </div>
          <div className="flex">
            <div className="">0140015195</div>
          </div>
          <div className="flex">
            <div className="tracking-wider">EXIM BANK (T) LTD</div>
          </div>
          <div className="flex">
            <div className="tracking-wider">NYERERE ROAD</div>
          </div>
          <div className="flex">
            <div className="">EXTNTZTZ</div>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="w-2/4">
          <div className=" my-4">
            We thank you for choosing Karimjee Value Chain Limited
          </div>

          <div className="font-bold ">Approved By:</div>
          <div className="flex my-2">
            <div className="mr-2">Operations Manager:</div>
            <div className="">____________________________________</div>
          </div>
          <div className="flex mt-3">
            <div className="mr-2">Signature:</div>
            <div className="">
              ________________________________________________
            </div>
          </div>
        </div>
        <div className="w-1/4"></div>
        <div className="w-1/4">
          <div className="mt-6 mb-1 font-bold">Summary in TZS</div>
          <div className="border border-gray-400">
            <div className="flex justify-between">
              <div className="p-1 font-bold">SUBTOTAL</div>
              <div className="p-1">{formatMoney(totalBeforeTax)}</div>
            </div>
            <div className="flex justify-between">
              <div className="p-1 font-bold">VAT</div>
              <div className="p-1">{formatMoney(totalTax)}</div>
            </div>
            <div className="flex justify-between">
              <div className="p-1 font-bold">TOTAL</div>
              <div className="p-1">{formatMoney(totalAfterTax)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
