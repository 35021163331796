import moment from "moment";
import logistics from "../../../assets/images/kvcl_white.png";
export default function InvoiceLogo({ service_date, invoice_no }: any) {
  return (
    <div>
      <div className="flex justify-between items-center ">
        <img className="h-20 -ml-2" src={logistics} alt="" />
      </div>
      <div className="flex justify-between mb-2 -mt-2 pr-3 ">
        <div className=""></div>
        <div className="font-bold text-sm -ml-8">TAX INVOICE</div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-col">
          <span className="">KARIMJEE VALUE CHAIN LIMITED</span>
          <span className="">P.O BOX 409</span>
          <span className="uppercase">Sokoine Drive</span>
          <span className="">DAR ES SALAAM</span>
          <span className="">TANZANIA</span>
        </div>
        <div className="flex flex-col">
          <span className="">
            <span className="font-bold">INVOICE No.</span> {invoice_no}
          </span>
          <span className="">
            <span className="font-bold">DATE : </span>
            <span className="ml-2">
              {moment(service_date.toString()).format("DD.MM.YYYY")}
            </span>
          </span>
          <span className="text-white">-</span>
          <div className="flex">
            <div className="font-bold mr-2">TIN : </div>106-207-674
          </div>
          <div className="flex">
            <div className="font-bold mr-2">VRN : </div>40-023170-U
          </div>
        </div>
      </div>
    </div>
  );
}
