import splitIntoChunks from "../../../utils/splitIntoChunks";
import TransferWrapperPrice from "./TransferWrapperPrice";
import "./custom.css";

export default function TransferDocumentPrice({
  selected,
  company,
  currency,
  exchangeRate,
  fields,
}: any) {
  const chunks = splitIntoChunks(selected, 28);
  return (
    <div className="book">
      {chunks?.map((page: any, index: number) => (
        <div className="page-transfer" key={index}>
          <div className="subpage-transfer">
            <TransferWrapperPrice
              selected={page}
              fields={fields}
              pageno={index + 1}
              pages={chunks.length}
              totalItems={selected}
              company={company}
              currency={currency}
              exchangeRate={exchangeRate}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
