import LinearLink from "./LinearLink";

export default function ValueHead() {
  const links = [
    { name: "Transfer Note", to: "/value/chain/transfer" },
    { name: "Invoice", to: "/value/chain/invoice" },
  ];

  return (
    <div className="px-4 border-b  bg-gray-50">
      <div className="flex items-center">
        {links.map((link: any, index: number) => (
          <LinearLink title={link?.name} to={link?.to} key={index} />
        ))}
      </div>
    </div>
  );
}
