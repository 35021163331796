import logo from "../../../../assets/images/kvcl_white.png";
import qrcode from "../../../../assets/images/qrcode.png";

export default function TransferHead({ company }: any) {
  return (
    <div className="flex justify-between items-center mb-6">
      <div className="w-1/3">
        <img className="mx-auto h-24 w-32" src={qrcode} alt="" />
        <div className="flex flex-col items-center">
          <div className="font-bold">8001 1277</div>
          <div className="uppercase">Karimjee Value Chain</div>
        </div>
      </div>
      <div className="w-1/3">
        <img className="mx-auto h-20" src={logo} alt="" />
      </div>
      <div
        style={{ fontSize: "12px" }}
        className="flex flex-col items-end font-bold w-1/3"
      >
        <span className="-mt-1"  style={{ fontSize: "16px" }}>DAR ES SALAAM</span>
        <span>{company?.address1}</span>
        {/* <span>{company?.address2}</span> */}
        {/* <span>{company?.address3}</span> */}
        <span>{company?.address4}</span>
        <span className="mt-2">TIN: 106-207-674</span>
        <span>VRN: 40-023170-U</span>
      </div>
    </div>
  );
}
