import { ARPS_BASE_URL } from "../utils/urls";

const postDataArps = async (data: any, uri: any) => {
  const response = await fetch(`${ARPS_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const result = await response.json();
  return result;
};

export default postDataArps;
