export default function TransferVehicle({ selected, pageno, pages }: any) {
  return (
    <div className="border border-black  text-xs text-black mt-1">
      <div className="grid grid-cols-12 gap-0 bg-gray-300">
        <div className="col-span-3 px-1  border-b border-black">
          Make & Model
        </div>
        <div className="col-span-3 px-1  border-b  border-black">
          Chassis No.
        </div>
        <div className="col-span-3 px-1  border-b  border-black">
          Engine No.
        </div>
        <div className="col-span-1 px-1  border-b  border-black">Mileage</div>

        <div className="col-span-1 px-1  border-b  border-black">Time</div>
        <div className="col-span-1 px-1  border-b  border-black">Page</div>
      </div>
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-2 p-1 border-b  border-black"></div>
        <div className="col-span-2 p-1 border-b  border-black"></div>
        <div className="col-span-2 p-1 border-b  border-black"></div>
        <div className="col-span-2 p-1 border-b  border-black"></div>
        <div className="col-span-2 p-1 border-b  border-black">0</div>
        <div className="col-span-1 p-1 border-b  border-black">-</div>
        <div className="col-span-1 p-1 border-b  border-black text-center font-bold">
          {pageno} / {pages}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-0 bg-gray-300">
        <div className="col-span-3 px-1  border-b border-black">
          Reference No.
        </div>
        <div className="col-span-2 px-1  border-b  border-black">Reg No.</div>
        <div className="col-span-2 px-1  border-b  border-black">Reg Date.</div>
        <div className="col-span-2 px-1  border-b  border-black">VSB No.</div>

        <div className="col-span-2 px-1  border-b  border-black">W.I.P No.</div>
        <div className="col-span-1 px-1  border-b  border-black">Job No.</div>
      </div>
      <div className="grid grid-cols-12 w-full font-bold">
        <div className="col-span-3 p-1"></div>
        <div className="col-span-2 p-1"></div>
        <div className="col-span-2 p-1"></div>
        <div className="col-span-2 p-1"></div>
        <div className="col-span-2 p-1">{selected?.wip}</div>
        <div className="col-span-1 p-1 text-center">0</div>
      </div>
    </div>
  );
}
