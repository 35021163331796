import { Link } from "react-router-dom";

export default function LogisticsHead() {
  return (
    <div className="px-4 border-b  bg-gray-50">
      <div className="flex items-center">
        <div className="mr-1 text-toyota p-2 border-b border-toyota bg-gray-200 hover:text-toyota hover:cursor-pointer hover:bg-gray-200">
          <Link
            to={"/logistics/invoice"}
            className={"hover:text-toyota text-toyota"}
          >
            <div className="flex flex-col px-2 items-center">Invoice</div>
          </Link>
        </div>

        <div
          className="mr-1 text-gray-500 p-2 hover:text-toyota  hover:cursor-pointer hover:bg-gray-200 
        "
        >
          <Link
            to={"/logistics/debitnote"}
            className="hover:text-toyota  text-gray-900"
          >
            <div className="flex flex-col px-2 items-center">Debit Note</div>
          </Link>
        </div>
        <div
          className="mr-1 text-gray-500 p-2 hover:text-toyota  hover:cursor-pointer hover:bg-gray-200 
        "
        >
          <Link
            to={"/logistics/creditnote"}
            className="hover:text-toyota  text-gray-900"
          >
            <div className="flex flex-col px-2 items-center">Credit Note</div>
          </Link>
        </div>
        <div
          className="mr-1 text-gray-500 p-2 hover:text-toyota  hover:cursor-pointer hover:bg-gray-200 
        "
        >
          <Link
            to={"/logistics/receipt"}
            className="hover:text-toyota  text-gray-900"
          >
            <div className="flex flex-col px-2 items-center">Receipt</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
