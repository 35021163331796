import logo from "../../../assets/images/logo.png";
import qrcode from "../../../assets/images/qrcode.png";

export default function TransferHead({ company }: any) {
  return (
    // <div className="flex justify-between items-center mb-6">
    //   <div className="w-1/3">
    //     <img className="mx-auto h-24 w-32" src={qrcode} alt="" />
    //     <div className="flex flex-col items-center">
    //       <div className="font-bold">8001 1277</div>
    //       <div className="uppercase">Toyota Tanzania Ltd</div>
    //     </div>
    //   </div>
    //   <div className="-mr-8 w-1/3">
    //     <img className="mx-auto h-20" src={logo} alt="" />
    //   </div>
    //   <div
    //     style={{ fontSize: "12px" }}
    //     className="flex flex-col font-bold w-1/3"
    //   >
    //     <span className="text-lg -mt-1">{company?.label}</span>
    //     <span>{company?.address1}</span>
    //     <span>{company?.address2}</span>
    //     <span>{company?.address3}</span>
    //     <span>{company?.address4}</span>
    //     <span className="mt-2">TIN: 100-146-304</span>
    //     <span>VRN: 10-0066645-E</span>
    //   </div>
    // </div>
    <div
      className="flex justify-between items-center mb-6"
      style={{ fontSize: 12 }}
    >
      <div className="w-1/4">
        <img className="mx-auto h-20 w-24" src={qrcode} alt="" />
        <div className="flex flex-col items-center">
          <div className="font-bold">8001 1277</div>
          <div className="uppercase">Toyota Tanzania Ltd</div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-2/4">
        <span
          className="-mt-1"
          style={{
            fontSize: 22,
            fontWeight: "bold",
            marginBottom: 10,
          }}
        >
          Toyota Tanzania Ltd
        </span>
        <img className="mx-auto h-16" src={logo} alt="" />
      </div>
      <div
        className="flex flex-col justify-end font-bold w-1/4"
        style={{ fontWeight: "bold" }}
      >
        <span style={{ fontSize: 15 }}>{company?.label}</span>
        <span>{company?.description}</span>
        <span>{company?.address1}</span>
        {/* <span>{company?.address2}</span> */}
        <span>{company?.address3}</span>
        <span>{company?.address4}</span>
        <span style={{ marginTop: 2 }}>TIN: 100-146-304</span>
        <span>VRN: 10-0066645-E</span>
      </div>
    </div>
  );
}
