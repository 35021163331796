import { Link } from "react-router-dom";
import logo from "../../assets/images/kjl_white.png";
import { RiLoginBoxLine } from "react-icons/ri";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Profile from "./Header/components/Profile";

export default function Header() {
  const { token } = useContext(AuthContext);
  const [isVisible, setVisible] = useState(false);

  return (
    <div className="h-16 bg-toyota flex flex-row justify-between items-center pr-5">
      <div className="flex flex-row items-center  ">
        <div className="flex justify-center items-center border-r border-indigo-900 w-24">
          <img src={logo} alt="Logo" height={15} width={35} />
        </div>
        <div className="ml-4 text-white text-2xl font-bold hidden sm:block md:text-2xl">
          KJG Invoices
        </div>
        <span className="ml-4 block sm:hidden text-2xl font-normal text-white">
          KJL
        </span>
      </div>
      <div className="flex flex-row items-center">
        {token ? (
          <div className="z-50">
            <Profile isVisible={isVisible} setVisible={setVisible} />
          </div>
        ) : (
          <Link
            to={"/"}
            className="mx-4 flex justify-center items-center text-white rounded-md py-1"
          >
            <RiLoginBoxLine color="#FFFFFF" size={22} />
          </Link>
        )}
      </div>
    </div>
  );
}
