import { slice } from "lodash";
import InvoiceItemMain from "./InvoiceItemMain";

export default function InvoiceItemsMain({ selected, isGatePass }: any) {
  const available = selected.length;

  const data = new Array(32).fill({ id: 1 });
  let few = slice(data, 0, 32 - available);

  return (
    <div style={{ height: "100%" }} className="mt-2  border border-black">
      <div className="grid grid-cols-12 border-b  border-black  bg-gray-300">
        <div className="col-span-11 px-1 py-1 font-bold border-r border-black text-black  text-left">
          Description of Good / Services
        </div>
        <div className="col-span-1 px-1  py-1 font-bold text-black text-center">
          Qty
        </div>
      </div>
      {isGatePass ? (
        <div className="text-gray-200 text-9xl font-bold ml-8">Gatepass</div>
      ) : null}
      <div className={`${isGatePass ? "-mt-32" : ""}`}>
        {selected.map((item: any, index: number) => (
          <InvoiceItemMain
            style={{ color: "red" }}
            key={index}
            index={index}
            item={item}
          />
        ))}

        {few.map((item: any, index: number) => (
          <div className="grid grid-cols-12 items-center" key={index}>
            <div className="col-span-11 px-1 border-r text-white  border-black  text-center">
              -
            </div>
            <div className="col-span-1 px-1 text-white border-black text-right">
              -
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
