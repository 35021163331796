import logo from "../../../assets/images/logo.png";
import logistics from "../../../assets/images/logistics.png";

export default function TransferHead() {
  return (
    <div className="flex justify-between items-center mb-6">
      <div className="">
        <img className="h-24 -ml-4" src={logistics} alt="" />
      </div>
      <div className="-ml-8">
        <span style={{ fontSize: 18 }} className="font-bold">
          TOYOTA TANZANIA LTD
        </span>
        <img className="mx-auto h-20 mt-2" src={logo} alt="" />
      </div>
      <div style={{ fontSize: "12px" }} className="flex flex-col font-bold">
        <span style={{ fontSize: 18 }}>DAR ES SALAAM</span>
        <span>Nyerere Road P.O Box 9060</span>
        <span>Dar es salaam</span>
        <span>Tel: (255) 22 2866815-9, 2866352-8</span>
        <span>Fax: (255) 22 2866814</span>
        <span>TIN: 100-146-304</span>
        <span>VRN: 10-0066645-E</span>
      </div>
    </div>
  );
}
