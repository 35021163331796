
import InvoiceItemsPrice from "../../../transfers/components/InvoiceItemsPrice";
import InvoiceTotalsPrice from "../../../transfers/components/InvoiceTotalsPrice";
import TransferCust from "../../../transfers/components/TransferCust";
import TransferNumber from "../../../transfers/components/TransferNumber";
import TransferTitle from "../../../transfers/components/TransferTitle";
import TransferVehicle from "../../../transfers/components/TransferVehicle";
import TransferHead from "./TransferHead";

export default function TransferWrapperPrice({
  selected,
  pageno,
  pages,
  totalItems,
  company,
  fields
}: any) {
  const docNo = selected[0]?.document_no;
  return (
    <div id="transfer-doc" style={{ fontSize: "11px" }}>
      <div className="">
        <TransferHead company={company} />
        <TransferTitle />
        <TransferCust selected={selected} fields={fields} />
        <TransferVehicle selected={selected[0]} pageno={pageno} pages={pages} />
        <TransferNumber number={docNo} />
        <div style={{ height: "100%" }}>
          <InvoiceItemsPrice selected={selected} />
          <InvoiceTotalsPrice
            selected={selected}
            totalItems={totalItems}
            pageno={pageno}
            pages={pages}
          />
        </div>
      </div>
    </div>
  );
}
